<template>
    <div class="AboutView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerFourComponent>
              <template slot="FirstBox">
                <v-img :src="require('@/assets/logoDark.png')" height="100px" width="300px" contain />
              </template>
              <template slot="SecondBox">
                <div>
                  <div class="my-5">
                    <div class="font-italic text-h4"><strong>Digital Omni-Payment Solution</strong></div>
                    <div class="font-italic text-h5"><strong>Empowering new revenue streams</strong></div>
                  </div>
                  <div class="text-h6 font-weight-light font-italic" style="padding: 0 20%">
                    <p>Digital Omni-Payment Processor and Gateway Industry leading Fully integrated Check-out Solution including FIAT, Digital & Crypto</p>
                  </div>
                </div>
              </template>
              <template slot="ThreeBox">
                <TextStepComponent :limit="4" dark>
                  <template slot="content">
                    <v-stepper-content step="1">
                      1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquipiu
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      2 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquipiu
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      3 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquipiu
                    </v-stepper-content>
                    <v-stepper-content step="4">
                      4 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquipiu
                    </v-stepper-content>
                  </template>
                </TextStepComponent>
              </template>
              <template slot="FourBox">
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <div class="my-5">
                        <div class="font-italic text-h5"><strong>Early special offers for our founding partners</strong></div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="Center" class="text-h5 font-weight-light font-italic hidden-lg-and-down">
                      <div align="left" style="width: 300px">
                        <ul>
                          <li>Credit Cards</li>
                          <li>Digital Payments</li>
                          <li>Crypto Capable</li>
                        </ul>
                      </div>
                      <v-btn dark class="mt-5 px-10 py-8 Button" color="#2D3843">View More</v-btn>
                    </v-col>
                    <v-col align="center">
                      <v-img :src="require('@/assets/pos.png')" max-width="300px" contain />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerFourComponent>
          </v-col>
        </v-row>
        <v-row dense ref="scroll[5]">
          <v-col>
            <BannerSingleComponent>
              <template v-slot:content>
                <div class="my-15">
                  <strong>Founder</strong> Message
                </div>
                <div class="text-h5 font-weight-light font-italic" style="padding: 0 20%">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad magnasdfar minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquipiu.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquipiu</p>
                </div>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense ref="scroll[5]">
          <v-col>
            <BannerSingleComponent>
              <template v-slot:content>
                <v-container fluid class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-img :src="require('@/assets/teams/joey.jpg')" contain width="300px" max-width="300px" height="300px" />
                      <div class="text-h5 mt-5 font-weight-black" align="left" style="max-width: 300px">JOEY ADLER</div>
                      <div class="text-h5 font-weight-light" align="left" style="max-width: 300px">Co-Founder & Co-CEO</div>
                    </v-col>
                    <v-col align-self="center" align="center">
                      <v-img :src="require('@/assets/teams/jose.jpg')" contain width="300px" max-width="300px" height="300px" />
                      <div class="text-h5 mt-5 font-weight-black" align="left" style="max-width: 300px">JOSE ROSAS</div>
                      <div class="text-h5 font-weight-light" align="left" style="max-width: 300px">Co-Founder & Co-CEO</div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" align-self="center" align="center">
                      <div class="my-15">
                        <strong>Meet</strong> Out Team
                      </div>
                      <div class="text-h5 font-weight-light font-italic" style="padding: 0 20%">
                        <p>We offer a hybrid working environment with employees all around the world.</p>
                        <p>At Bloqs4Good, we believe different perspectives lead to incredible results. We stride to create a working environment which is inclusive for all.</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense ref="scroll[1]">
          <v-col class="py-0">
            <VideoBannerComponent :src="require('@/assets/videos/demo2.mp4')" />
          </v-col>
        </v-row>
        <v-row dense ref="scroll[3]">
          <v-col>
            <BannerSingleComponent>
              <template v-slot:content>
                <strong>Start</strong> creating with <strong>Bloqs4Good</strong>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense ref="scroll[8]">
          <v-col>
            <PartnersComponent />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_AboutView.scss'
import PartnersComponent from '@/components/PartnersComponent/PartnersComponent'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerFourComponent from '@/components/BannerFourComponent/BannerFourComponent'
import TextStepComponent from '@/components/TextStepComponent/TextStepComponent'
import meta from '@/mixins/meta'
export default {
  name: 'AboutView',
  components: { TextStepComponent, BannerFourComponent, VideoBannerComponent, BannerSingleComponent, PartnersComponent },
  mixins: [meta]
}
</script>
