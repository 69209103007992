<template>
    <div class="AboutUsView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">Connecting Humanity And Technology, let’s CHAT</span>
                <span style="font-size: 0.9rem" class="mx-5"><a href="/contact">Contact Us</a></span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/aboutus/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/aboutus/aboutus.svg')" max-width="369px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          We believe that technology should serve humanity not use it. We believe that technology should be available to everyone cost-effectively, efficiently, with security and privacy. We believe that technology must be driven by humanity and why our motto is: Let’s CHAT - Connecting humanity and technology.<br><br>
                          We are excited to launch the first fully integrated blockchain ecosystem. From our plug-and-play hardware, to metaverse NFT engine, and to innovative DeFi options, there’s nothing like it. We believe in connecting humanity and technology, to empower individuals, creators, and SMEs to thrive. We’re delivering solutions that are ready to scale without compromise to security, speed, decentralization, environment, and humanity. We are proudly donating up to 10% of our net revenue from Dept. of Good to
                          non-profits and community organizations.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <div style="font-size: 2rem; font-weight: 300; font-style: italic" class="my-10">
                    Meet the founders of Bloqs4Good and hear what they think about how decentralization is changing everything.
                  </div>
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          At Bloqs4Good we are reinventing technology using a humanistic approach to empower individuals and SMEs (small and medium sized enterprises). We believe everyone should have full control and ownership of their data. We manage our business based on our personal core values of trust, integrity, and sustainability. Our vision is to create technology that encompasses our core values and is accessible to everyone on the planet.
                          <br><br>Today, Bloqs4Good is a decentralized technology company offering both hardware and software solutions under our core tech; the next generation solutions for Digital Asset Management (DAM). Our platform and tools enable consumers, SMEs, and corporations to maximize their assets, realize opportunities for new avenues of growth and profitability in the future economy, with total security and privacy.
                        </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Let’s CHAT</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/aboutus/01.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/aboutus/02.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense ref="scroll[5]">
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container fluid class="my-15" style="max-width: 2048px;">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-img :src="require('@/assets/teams/joey.jpg')" contain width="300px" max-width="300px" height="300px" />
                      <div class="text-h5 mt-5 font-weight-black" align="left" style="max-width: 300px">JOEY ADLER</div>
                      <div class="text-h5 font-weight-light" align="left" style="max-width: 300px">Co-Founder & Co-CEO</div>
                    </v-col>
                    <v-col align-self="center" align="center">
                      <v-img :src="require('@/assets/teams/jose.jpg')" contain width="300px" max-width="300px" height="300px" />
                      <div class="text-h5 mt-5 font-weight-black" align="left" style="max-width: 300px">JOSE ROSAS</div>
                      <div class="text-h5 font-weight-light" align="left" style="max-width: 300px">Co-Founder & CSO</div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" align-self="center" align="center">
                      <div class="my-15">
                        <strong>Meet</strong> Out Team
                      </div>
                      <div class="text-h5 font-weight-light font-italic" style="padding: 0 20%">
                        <p>We offer a hybrid working environment with employees all around the world.</p>
                        <p>At Bloqs4Good, we believe different perspectives lead to incredible results. We stride to create a working environment which is inclusive for all.</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                        <div><span class="font-weight-bold" style="font-size: 150%">Pioneering the GOOD Economy</span></div>
                      <div>Technology to empower everyone</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large >Let’s CHAT</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-icon class="mr-2">mdi-chat-question-outline</v-icon>FAQ</template>
              <template slot="Content">Get answers to frequently asked questions on Bloqs4Good products and services.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-icon class="mr-2">mdi-rocket-launch-outline</v-icon>Get Started</template>
              <template slot="Content">Learn how to create an account, set up your Wallet4Good, and what you can do on Dept. of Good.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-icon class="mr-2">mdi-cart-outline</v-icon>Buying</template>
              <template slot="Content">Learn how to purchase NFTs, understand fees, and see our low gas fees on Dept. Of Good. </template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-icon class="mr-2">mdi-hand-extended-outline</v-icon>Selling</template>
              <template slot="Content">Learn how to list your NFTs for sale and understand the different ways to list them.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">05</template>
              <template slot="Title"><v-icon class="mr-2">mdi-progress-pencil</v-icon>Creating</template>
              <template slot="Content">Learn how to create your NFTs/SFTs and how to create your collection and galleries.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">06</template>
              <template slot="Title"><v-icon class="mr-2">mdi-shield-check-outline</v-icon>User Safety</template>
              <template slot="Content">Learn more about anti-fraud and user safety for our users.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">07</template>
              <template slot="Title"><v-icon class="mr-2">mdi-handshake-outline</v-icon>Partners</template>
              <template slot="Content">Learn how can you partner with us to empower your organizations or create NFT drops.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Get iD4Good, <strong>start now</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/apple-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_AboutUsView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'

export default {
  name: 'AboutUsView',
  components: { SimpleInfoBoxComponent, BannerTwoComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
