<template>
    <div class="TextStepComponent">
      <v-stepper v-model="step" style="background: transparent" elevation="0" :dark="dark">
        <v-stepper-items>
          <slot name="content">
            <v-stepper-content step="1">
              Demo
            </v-stepper-content>
          </slot>
          <div align="left">
            <v-btn x-large icon @click="prevStep"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
            <v-btn x-large icon @click="nextStep"><v-icon x-large>mdi-arrow-right-circle</v-icon></v-btn>
          </div>
        </v-stepper-items>
      </v-stepper>
    </div>
</template>

<script>
import './_TextStepComponent.scss'
export default {
  name: 'TextStepComponent',
  props: {
    limit: Number,
    dark: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      step: 1
    }
  },
  methods: {
    nextStep () {
      this.step++
      if (this.step > this.limit) {
        this.step = 1
      }
    },
    prevStep () {
      this.step--
      if (this.step < 1) {
        this.step = this.limit
      }
    }
  }
}
</script>
