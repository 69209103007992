<template>
    <div class="Community4GoodView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">As we have traveled the world, we realize how so many do not participate in the economy. We were inspired by the wonderful people who drive communities We want to share with you</span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/community/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/community/community.svg')" max-width="369px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          At Bloqs4Good we have always been committed to our communities, we firmly believe that business, communities and prosperity come together in a direct relationship. How prospering a community can be depends on the success of its SMEs. The more successful they are, the better they can be an active participant in the community, and the happier the people who belong to it can be. Engage the small businesses in your community to join our movement, we will enable them with amazing tools to increase their business.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          We go beyond… We want to be involved with community organizations, our motivation is to collaborate to create direct association between business and communities, putting our focus on several programs related to art and children empowerment, and we are totally open to new initiatives that can emerge from our partners and the communities they belong and support. We look forward to a better future, let’s chat and do it together.
                        </div>
                    </v-col>
                  </v-row>
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <div style="color: #6EACE8; font-size: 1rem">Charity Organizations</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="369"
                          :duration="2"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Being benefitted<br><br>
                        By our platform</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <div style="color: #6EACE8; font-size: 1rem">Individuals</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="369"
                          :duration="2.5"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Sharing content<br><br>
                        In our network</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <div style="color: #6EACE8; font-size: 1rem">SMEs</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="369"
                          :duration="3"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Deploying with<br><br>
                        Our tools</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/community/01.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/community/02.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                        <div><span class="font-weight-bold">Empowering Communities</span></div>
                      <div>Our mission is to connect humanity and technology</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large >Let’s CHAT</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="min-height: 60vh" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Humanistic <strong>approach</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10" align="left">
                          It is implicit in everything we do and we honestly enjoy what we are doing. Is really inspiring to open new horizons for individuals and creators with amazing tools like Dept. of Good and Wallet4Good, where everybody can explore, publish, sell and buy Peer to Peer. Or to make life easier with strong security and full control like we do with iD4Good that we hope will soon set a new standard in user data protection. Or the ease of use and comfort we establish in our Pay4Good, where the SMEs can find the most affordable way to enhance revenues giving total freedom to customers. Or our Box4Good hardware and its multiple uses, from transaction booster to contactless physical PoS, including its Node4Good role that makes the owner to get new revenue streams by participating in our 4GoodNetwork.</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/community/banner2.png')" width="100%" height="100%" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">We are <strong>waiting you</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Everything is ready to bring more opportunities to people around the world and help them prosper. And there is more, we have much more to reveal including the next release of our developer toolkit, Sandbox, that will allow our users to create new business models and tools in our network.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Community4GoodView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'

export default {
  name: 'Community4GoodView',
  components: { BannerTwoComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
