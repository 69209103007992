<template>
    <div class="Network4GoodView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/4goodnetwork/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/4goodnetwork/4goodnetwork.svg')" max-width="300px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Our core technologies are working together to bring the 4GoodNetwork to life, the most versatile, secure and fastest blockchain-like network based on our proprietary Cloudless technology (patent granted). 4GoodNetwork is a unique multi-layer approach that has a distinct consensus mechanism per layer, promoting network robustness and transaction speeds. This amazing functionality enables a much better experience for users, and a huge step forward in the adoption of decentralized technologies, due to its unprecedented speed, reliability, security and cost reduction.</div>
                        <div style="max-width: 350px">
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-15">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          The first amazing impact of the 4GoodNetwork is the true democratization of decentralized networks as we know them today. For most of us Blockchain, Crypto, smart contract, dApps are complex concepts, but will be critical to understand as it will form important technology for the future.
                          <br><br>
                          We committed to building our technology with a humanistic approach, making it simpler for mass adoption always respecting privacy and security of all. We have built all the components for the future, which are integrated together and running on the 4GoodNetwork seamlessly and efficiently. From our ID4Good to Dept of Good our hybrid e-commerce platform and all in between, we are excited, and the future is here.</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-container>
                        <v-row>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/enviroment.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Environment Care
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Maximum power efficiency for more affordable and eco-friendly processing. Biodegradable device housing.
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/flexible.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Customizable
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Different colors and customization. Can be branded to meet client’s specifications
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/ubiquitous.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Node capabilities
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Safely can act as node in the 4GoodNetwork turning into a new revenue source for owners
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/comfortable.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Multifunctional
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Box4Good can handle diverse roles, from transaction booster to contactless Point of Sales
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Participate!</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/4goodnetwork/01.png')" width="100%" height="100%" min-height="100%">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/4goodnetwork/02.png')" width="100%" height="100%" min-height="100%">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                        <div><span class="font-weight-bold">Decentralized</span>Trust, <span class="font-weight-bold">Secure</span>Privacy</div>
                      <div><span class="font-weight-bold">Scalable</span>Growth</div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">01</template>
            <template slot="Title"><v-icon class="mx-2">mdi-star-outline</v-icon>Sustainable</template>
            <template slot="Content">
              <ul>
                <li>99.9% less energy used per transaction than ETH or BTC.</li>
                <li>Minimum node maintenance.</li>
                <li>Our hardware is built with environmentally friendly materials.</li>
              </ul>
            </template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">02</template>
            <template slot="Title"><v-icon class="mr-2">mdi-hand-extended-outline</v-icon>Accesible</template>
            <template slot="Content">
              <ul>
                <li>Affordable network fees.</li>
                <li>Frictionless and fast transactions for fiat or crypto.</li>
                <li>Private, secure, decentralized network deployed on plug&play hardware.</li>
              </ul>
            </template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">03</template>
            <template slot="Title"><v-icon class="mr-2">mdi-rocket-launch-outline</v-icon>Powerful</template>
            <template slot="Content">
              <ul>
              <li>Supports dApps, smart contracts, interactive NFTs, and more.</li>
              <li>Up to 250,000 transactions per second when fully deployed.</li>
              <li>Multi-layer network each with unique consensus.</li>
              <li>Enables marketplaces for analytics, storage, and processing of data.</li>
              </ul>
            </template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">04</template>
            <template slot="Title"><v-icon class="mr-2">mdi-shield-check-outline</v-icon>Easy set up</template>
            <template slot="Content">
              <ul>
              <li>Plug and play does not need configuration.</li>
              <li>Updated remotely.</li>
              <li>Signs-up and signs-in to the network automatically.</li>
              <li>Administration can be done from self-custody wallet as an NFT Hybrid Asset.</li>
              </ul>
            </template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
      </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Roles that <strong>fit</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Joining our network, is open to all users, from technology experts, to a novice at home. In late 2023 we will launch the full network and enable anyone to be part of our network, with a Box4Good which can be set up at home, connected to smart TVs with an HDMI cable. By having a B4G at home, we will be able to compensate owners for being part of network and sharing resources.
                          The B4G enables storage (fully encrypted and secure) of all digital assets, photos, music, personal documents of importance, ability to create and store NFTs.
                          Being part of the network allows us to help share resources with a variety of roles depending on everyone's desire. More to come soon.</div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/node4good/banner3.png')" width="100%" height="80vh" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="min-height: 60vh" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Be part <strong>now</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10" align="left">
                          When you identify yourself using iD4Good, or you pay using Pay4Good, or you mint an NFT using Wallet4Good or Dept. of Good you are actively participating as user, but if you want to go further, get in touch with us and get an active role that fits your expectations. We have subscription plans to be a Node Founder with virtual Node4Good, or you can get our Node4Good hardware and start holding the network in your location.
                        </div>
                        <div style="max-width: 350px">
                          <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Get iD4Good, <strong>start now</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Network4GoodView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'

export default {
  name: 'Network4GoodView',
  components: { BannerTwoComponent, SimpleInfoBoxComponent, VideoBannerComponent, BannerSingleDarkComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
