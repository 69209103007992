<template>
    <div class="Node4GoodView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">Node4Good can runs seamless virtually or in our Box4Good hardware</span>
                <span style="font-size: 0.9rem" class="mx-5"><a href="/box4good">Know Box4Good</a></span>

              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/node4good/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/node4good/node4good.svg')" max-width="300px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Node4Good comes to completely change the paradigm of decentralized networks. In our B4G family, everyone can participate and benefit by being part of the network. Node4Good can be a Virtual Node or a physical node. The Box4Good (physical Node) is simple and a great addition to manage our personal assets by digitizing them. Enjoy them on any screen. Your assets are encrypted and completely secure and best part; help fuel the transactions of te network and be compensated monthly. As our network grows and more transactions are generated, more compensation will be available for all our partners and loyal customers. </div>
                        <div style="max-width: 350px">
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          Everyone has the opportunity to be a partner! From individuals to organizations , join the network and be a part of this community. We have the right option for you with founding members nodes, retail nodes and consumers nodes. All will have the opportunity to generate revenue every month by participating in the 4GoodNetwork.</div>
                    </v-col>
                  </v-row>
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <div style="color: #6EACE8; font-size: 1rem">We have</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="500"
                          :duration="2"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Active nodes
                        <br><br>
                        Running</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <div style="color: #6EACE8; font-size: 1rem">We are</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="450"
                          :duration="2.5"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Different node
                        <br><br>
                        Owners</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <div style="color: #6EACE8; font-size: 1rem">We generated</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="250000"
                          :duration="3"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Equivalent in
                        <br><br>
                        dollars Rewards</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Get your Node</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/node4good/01.png')" width="100%" height="100%" min-height="100%">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/node4good/02.png')" width="100%" height="100%" min-height="100%">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                        <div>Keep it  <span class="font-weight-bold">Powered and get </span></div>
                      <div>rewarded</div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/node4good/banner2.png')" width="100%" height="100%" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="min-height: 60vh" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">So small, <strong>so powerful</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10" align="left">
                          Each Node4Good is able to process an infinite number of transactions, its hardware based version uses our Box4Good platform that is reliable, fast and very low in power consumption. Algorithms are fully optimized, so the node itself uses just a fraction of the energy spent in typical blockchain transactions (99,9% less energy per transaction), all in a small and plug & play and attractive package. Simply receive it, power up and connect to the local network, validate its ownership with your iD4Good app and you’ll be participating and earning without compromise anything of your security or privacy.</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Transaction <strong>army</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Under its small housing, a Node4Good is able to maintain the 4GoodNetwork running and processing thousands of transactions per second, making this new network the fastest and more efficient way to interact in a blockchain like network. Thanks to this disrupting conception of how a network is built in a real decentralized environment, Node4Good translates this true decentralization into great savings for users and environment reducing infrastructure, maintenance costs and energy consumption. And we have more projects to make it even more efficient and easy to mass adopt.</div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Get your node</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/node4good/banner3.png')" width="100%" height="80vh" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Sustainable</template>
              <template slot="Content">When fully deployed our network makes transactions greener, reaching 99.9% less energy consumption per transaction compared with ETH or BTC.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-icon class="mr-2">mdi-hand-extended-outline</v-icon>Accessible</template>
              <template slot="Content">Subscriptions available for Node keepers, plans can include physical or virtual node. Contractors are free to resell subscription.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-icon class="mr-2">mdi-rocket-launch-outline</v-icon>Powerful</template>
              <template slot="Content">Unique architecture makes the platform fast and efficient, with top performance and security.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-icon class="mr-2">mdi-shield-check-outline</v-icon>Easy</template>
              <template slot="Content"><li>Plug and play, no configuration needed.</li>
                <li>Updated remotely.</li>
                <li>Signs-up and signs-in to the network automatically.</li></template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Get iD4Good, <strong>start now</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Node4GoodView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'

export default {
  name: 'Node4GoodView',
  components: { BannerTwoComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
