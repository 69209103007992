<template>
    <div class="NotifyComponent">
      <v-container fluid class="fill-height pa-0 Container">
        <v-row dense>
          <v-col align-self="center" align="center" class="Banner">
            <slot name="content">
              <strong class="text-no-wrap">Launching</strong><v-img :src="require('@/assets/pay4good.svg')" max-height="32px" contain max-width="200px" class="d-inline-flex mx-2" style="position: relative; top: 8px" /> <span class="text-no-wrap">Sept. <strong>2022</strong></span>
            </slot>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_NotifyComponent.scss'

export default {
  name: 'NotifyComponent',
  data () {
    return {
      breadcrumbs: [
      ]
    }
  },
  mounted () {
    if (this.$route.name === 'home') {
      this.breadcrumbs.push({ text: 'HOME', href: '/', disabled: true })
    } else {
      this.breadcrumbs.push({ text: 'HOME', href: '/' })
    }
  }
}
</script>
