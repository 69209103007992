<template>
    <div class="iD4GoodView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">ID4Good is a private and secure identification to ensure your Wallet4Good is safe.</span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/id4good/woman.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/id4good/ID02.svg')" max-width="300px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          We introduce iD4Good, our self custody digital identity built from the basis of what is important to us and for everyone in the new all digital era: privacy, security and control. With iD4Good you get a post-quantum secure pass for all our current products like Wallet4Good or Dept. of Good, and many features across our ecosystem plus any future web portals and dApps from third parties, with the confidence no data would be shared without your express consent. Available now for Android, Ledger and iOS
                        </div>
                        <div style="max-width: 350px">
                          <v-container>
                            <v-row dense>
                              <v-col align-self="center">
                                <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                                  <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                                </v-btn>
                              </v-col>
                              <v-col align-self="center">
                                <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                                  <v-img :src="require('@/assets/apple-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          The magic behind iD4Good is to make it as easy as possible for the customer. Simply install it, create your iD and you’ll be ready to go. All is stored locally with the strongest encryption, and our company doesn’t get any information or tracking data from you or your device. From that point you are able to login or authorize as simple as scan and use your own device biometrics.
                        </div>
                    </v-col>
                  </v-row>
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <div style="color: #6EACE8; font-size: 1rem">Android</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="10000"
                          :duration="2"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Downloads</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <div style="color: #6EACE8; font-size: 1rem">Ledger</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="1000"
                          :duration="2.5"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Downloads</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <div style="color: #6EACE8; font-size: 1rem">iOS</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="10000"
                          :duration="3"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Downloads</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good" x-large>Get It Now!</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/id4good/man03.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/id4good/girl04.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                        <div>Safely and securely</div>
                      <div><span class="font-weight-bold">Authorize</span> and login</div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/id4good/woman02.png')" width="100%" height="80vh" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="min-height: 60vh" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">ID, trust <strong>and business</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10" align="left">
                          On the business side enabling iD4Good in web sites, web stores or any other application (including physical access validation) offers unparalleled trust and comfort for the clients, giving them one self-custody place to manage all keys and data protected, with the warranty of total control over which information is specifically authorized and the freedom to be suppressed by the owner from the app at the moment he wants.
                        </div>
                        <div style="max-width: 350px">
                          <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Get In Touch With Us!</v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Balance</template>
              <template slot="Content">Gives a balance between privacy and digital identity without compromising security and compliance.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Identification</template>
              <template slot="Content">Identification combining hardware and software, no collection of personal information (unless KYC compliance needs).</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Ecosystem Navigation</template>
              <template slot="Content">Available to navigate throughout our ecosystem, but not necessary. Meaning visitors without ID4Good can navigate our software.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Security Tool</template>
              <template slot="Content">An important tool for security and protection of personal digitized assets.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">05</template>
              <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Signing</template>
              <template slot="Content">Can be used to sign documents.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">06</template>
              <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Technology</template>
              <template slot="Content">One does need an ID4Good to use our technology.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid style="background-color: #EBEBEB">
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/id4good/studio.png')" width="100%" max-width="2048" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Simplicity <strong>and Safety</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          All data stored in iD4Good are kept in the local device only and with the strongest encryption. The App itself offers a safe way to create and load fully encrypted backups to prevent data loss, so; once a user creates an iD4Good; he can unlock his digital lifestyle as long as he wants no matter if he needs to change devices. Simplicity coming true, just tap and go. Location? Gender? Age? that information is yours only, and is not our business, so we never prompt them. With iD4Good we totally isolate your physical entity from your digital identity. Try it now.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <div style="font-size: 150%">
                      Get iD4Good, <strong>start now</strong>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div style="font-size: 1.4rem; line-height: 150%">
                        Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_iD4GoodView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'

export default {
  name: 'iD4GoodView',
  components: { BannerTwoComponent, SimpleInfoBoxComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
