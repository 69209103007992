<template>
    <div class="EmpowermentView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">We are proud of our ecosystem, which has been conceived to empower people and SMEs</span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/empowerment/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/empowerment/empowerment.svg')" max-width="369px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Are you an individual? A creator? A businessman? An SME? An entrepreneur? A charitable organization? A community organization? We are looking for you to build together the new paradigm in decentralized technology, offering amazing tools and platforms that will enable you or your organization to become part of the new Web3.0 revolution. We are excited to show you what our technology ecosystem can do for you, let’s try! Let’s CHAT!
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          We partner with community organizations focussing on art programs and children empowerment. We believe in a decentralized economy where individuals can transact in a true peer-to-peer network. Our technology reduces the friction and cost to exchange value - for everyone. Using our platform, individuals, creators, and SMEs can discover their true value.
                        </div>
                    </v-col>
                  </v-row>
                  <v-row class="my-10" v-if="false">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <div style="color: #6EACE8; font-size: 1rem">Charity Organizations</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="369"
                          :duration="2"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Being benefitted<br><br>
                        By our platform</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <div style="color: #6EACE8; font-size: 1rem">Individuals</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="369"
                          :duration="2.5"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Sharing content<br><br>
                        In our network</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <div style="color: #6EACE8; font-size: 1rem">SMEs</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="369"
                          :duration="3"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Deploying with<br><br>
                        Our tools</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/empowerment/01.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/empowerment/02.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                        <div><span class="font-weight-bold" style="font-size: 200%; line-height: 100%">Giving Back for Good</span></div>
                      <div>We donate 10% of all net transactional revenue from Dept. of Good</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large >Let’s CHAT</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/empowerment/banner2.png')" width="100%" height="100%" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-icon class="mr-2">mdi-face-agent</v-icon>Supporting</template>
              <template slot="Content">If you are wanting help and do not have a charitable status we are open to supporting you as well please let us know the work you are doing.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-icon class="mr-2">mdi-swap-horizontal</v-icon>Transactions</template>
              <template slot="Content">With every transaction automatic funds will be deposited into our empowerment wallet and every three months an advisory board will distribute the funds to our empowerment partners.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-icon class="mr-2">mdi-pencil-outline</v-icon>Create Your Space</template>
              <template slot="Content">We will ask you to create your own space on Dept of Good and keep updating it and finding innovative ways to raise money through NFTs or sales.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-icon class="mr-2">mdi-google-circles-communities</v-icon>Community</template>
              <template slot="Content">Engage the small business in your community to join the movement we will enable them with tools to increase their business. </template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Get iD4Good, <strong>start now</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_EmpowermentView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'

export default {
  name: 'EmpowermentView',
  components: { BannerTwoComponent, SimpleInfoBoxComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
