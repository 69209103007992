<template>
    <div class="HomeView">
      <v-container fluid class="pa-0">
        <v-row dense v-if="false">
          <v-col>
            <NotifyComponent class="slide-in-top" style="box-shadow: 0 0 10px #00000044" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/beyondBoundaries-back.jpg')" width="100%" height="60vh" position="center 0">
                  <div v-if="false" style="position: absolute; z-index: -1; top: 0; width: 100%; height: 100%; opacity: 50%; background-color: #003C81"></div>
                  <v-container class="fill-height" style="max-width: 1200px">
                    <v-row>
                      <v-col align-self="start" align="right">
                        <div class="d-flex" style="align-items: center">
                          <div class="mx-2" style="background-color: #ffffff88; backdrop-filter: blur(30px); width: 30px; height: 150px "> </div>
                          <div style="opacity: 0.7; color: white; font-size: 200%; font-weight: 900; line-height: 100%" align="left">
                            Beyond Boundaries
                          </div>
                        </div>
                      </v-col>
                      <v-col align-self="center" align="left" class="font-weight-medium" style="font-size: 70%; color: white">
                        We built technology to empower humanity, facilitating the journey from legacy to tomorrow’s decentralized economy. We innovate for our partner’s businesses with next-gen identity, payments, and asset management.
                      </v-col>
                    </v-row>
                  </v-container>
                </v-img>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row v-if="false" dense class="hidden-sm-and-down">
          <v-col>
            <BannerSingleComponent style="background-color: #eef2f5; color: #2F3D4D" :fluid="true" :custom-style="{ 'border-bottom': '0' }">
              <template slot="content">
                  <v-container fluid style="max-width: 1200px">
                  <v-row dense>
                    <v-col align="left" align-self="center">
                      <div class="mt-5" align="left">
                        <div class="font-weight-bold" style="font-size: clamp(12px, 6vw, 70px) !important; line-height: 100% !important;"><span class="font-weight-light">Omni</span>Payment<div class="font-weight-light">Solution</div></div>
                        <div class="font-weight-light ml-1" style="font-size: clamp(12px, 2.6vw, 36px) !important; line-height: 100% !important;">Empowering new revenue streams</div>
                      </div>
                      <div class="font-weight-light mt-5" style="font-size: clamp(12px, 1.5vw, 22px) !important; line-height: 140%; margin-bottom: calc(-2 * clamp(12px, 1.5vw, 22px));">
                        <div>Most Comprehensive Secure and Fastest Integrated<br> Check-Out Solution, able to process:</div>
                        <div align="right" class="mr-12" style="position: relative; top: calc(-2 * clamp(12px, 1.5vw, 22px))">
                          <div class="font-weight-light my-3" align="left" style="width: 180px">
                            <div>• Credit Cards</div>
                            <div>• Digital Payments</div>
                            <div>• Crypto</div>
                          </div>
                        </div>
                      </div>
                      <div class="font-weight-regular mt-5" style="font-size: clamp(12px, 1.5vw, 22px) !important; color: #6EACE8">
                        <div>With all the <strong>Security</strong> and <strong>Privacy</strong> of our Web 3.0 Technology</div>
                      </div>
                    </v-col>
                    <v-col align="center">
                      <v-img rel="preload" :src="require('@/assets/PayTop.png')" max-height="50vh" aspect-ratio="1" contain class="mr-15">
                        <div style="position: absolute; bottom: 0; right: 0" v-if="false">
                          <v-btn small color="#2F3D4D" dark filled rounded class="">More</v-btn>
                        </div>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row v-if="false" dense class="hidden-md-and-up">
          <v-col>
            <BannerSingleComponent style="background-color: #EFF1F6; color: #2F3D4D" :fluid="true">
              <template slot="content">
                <div>
                  <div class="mt-5">
                    <div class="font-italic font-weight-bold" style="font-size: clamp(12px, 6vw, 50px) !important; line-height: 100% !important;"><span class="font-weight-light">Omni</span>Payment<div class="font-weight-light">Solution</div></div>
                    <div class="font-weight-light ml-1" style="font-size: clamp(12px, 4vw, 40px) !important; line-height: 100% !important;">Empowering new revenue streams</div>
                  </div>
                  <div class="mt-5" style="max-width: 100vw">
                    <v-img rel="preload" :src="require('@/assets/PayTop.png')" max-width="90vw" height="40vh" contain>
                      <div style="position: absolute; bottom: 0; right: 0" v-if="false">
                        <v-btn small dark filled rounded class="font-italic">More</v-btn>
                      </div>
                    </v-img>
                  </div>
                </div>
                <div style="position: absolute; right: 0" class="BannerIconPay4Good">
                </div>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <!-- v-row dense class="hidden-md-and-up">
          <v-col>
            <BannerSingleDarkComponent>
              <template slot="content">
                <div>
                  <div class="py-15 my-5" style="background-color:#3D81C3">
                    <div>
                      <div class="font-weight-bold px-5 mt-5" style="font-size: 2rem !important; line-height: 2rem">
                         LET'S C.H.A.T.
                      </div>
                      <div class="font-weight-light px-5" style="font-size: 1.2rem !important; line-height: 1.4rem;">
                        CONNECTING HUMANITY AND TECHNOLOGY
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row -->
        <v-row dense>
          <v-col>
            <BannerSingleComponent class="my-3 py-10" :fluid="true" style="background-color: #3D81C3" :custom-style="{ 'border': '0' }">
              <template v-slot:content>
                <v-container style="min-height: 150px; max-width: 1200px">
                  <v-row>
                    <v-col align-self="center" align="center" style="color: #ffffff">
                      <div class="font-weight-bold px-5 mt-5" style="font-size: clamp(30px, 4vw, 50px); line-height: 100%">
                        LET'S C.H.A.T.
                      </div>
                      <div class="px-5" style="font-size: clamp(10px, 3vw, 30px); line-height: 100%">
                        CONNECTING HUMANITY AND TECHNOLOGY
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent :custom-style="{ border: '0' }" fluid class="">
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/versatile-background.jpg')" width="100%" height="70vh" style="position: relative;">
                  <v-container fluid class="fill-height" style="color: white">
                    <v-row dense>
                      <v-col align-self="left" align="left">
                      </v-col>
                      <v-col cols="12" md="6" align-self="center" align="">
                        <div class="px-5 font-weight-light" style="
                          align-self: self-end;
                          align-items: end;
                          position: absolute;
                          top: 0;
                          right: 0;
                          width: 50%;
                          height: 100%;
                          min-width: 400px;
                          background: #C5769E;
                          opacity: 0.5;
                          z-index: -1;
                          "></div>
                        <div align="left"  class="align-center mx-4 mt-2 font-weight-light" style="">
                          <div align="left" class="mt-5" style="font-weight: 900; font-size: 140%; line-height: 1; max-width: 550px;">Versatile payments</div>
                          <div class="mx-0 my-5" style="background-color: #ffffff88; backdrop-filter: blur(30px); width: 100%; max-width: 550px; height: 20px "> </div>
                          <div align="left" style="line-height: 100%; font-weight: 400; font-size: 50%; max-width: 550px;" class="mt-2">
                            Pay4.Global is the future of payment platforms, with one point of integration able to accept Credit Cards, Digital, Crypto tokenization. Able to integrate payments at a local level. Important AML and Fraud prevention protection as well as Merchant business tools for SMEs
                          </div>
                          <div align="center" style="max-width: 550px;" class="mt-10">
                            <v-img  rel="preload" align="center" :src="require('@/assets/logos/pay4global-white.svg')" max-width="350px" ></v-img>
                          </div>
                          <div align="center" style="max-width: 550px;" class="mt-10">
                            <v-btn  rounded color="#17D1FF" class="mx-5 my-5 pa-5 white--text" to="/pay4global" text-color="#ffffff">Learn More</v-btn>
                            <v-btn  rounded color="#6FA2AF" class="mx-5 my-5 pa-5 white--text" to="/contact">Contact Us </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-img>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col style="background-color: #3D81C3" class="mb-3">
            <div style="opacity: 0.7; position: absolute; background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%); height: 130px; width: 100vw; ">
            </div>
            <BannerSingleComponent class="mb-3" :fluid="true" :custom-style="{ 'border': '0' }">
              <template v-slot:content>
                <v-container style="min-height: 150px; max-width: 1200px; z-index: 1">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-img  rel="preload" align="center" :src="require('@/assets/logos/pay4good-white.svg')" max-width="350px" ></v-img>
                    </v-col>
                    <v-col align-self="center" align="left">
                      <div style="color: white; font-size: 80%; font-weight: 600">Engage community</div>
                      <div class="my-1" style="background-color: #ffffff88; backdrop-filter: blur(30px); width: 100%; max-width: 550px; height: 20px "> </div>
                      <div style="color:white; font-size: 50%" class="my-2">
                        As part of the Pay4.Global platform, Pay4Good shares all its cutting edge technology with communities, helping local merchants to raise funds for important community empowerment. A payment method for GOOD.
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent :custom-style="{ border: '0' }" fluid class="mb-3">
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/disruptingBackground.jpg')" width="100%" height="70vh" style="position: relative;">
                  <v-container fluid class="fill-height" style="color: white">
                    <v-row dense>
                      <v-col cols="12" md="6" align-self="center" align="">
                        <div align="left" class="px-5 font-weight-light" style="
                          position: absolute;
                          top: 0;
                          left: 0%;
                          width: 50%;
                          height: 100%;
                          background: #003C81;
                          min-width: 400px;
                          opacity: 50%;
                          z-index: -1;
                          "></div>
                        <div align="right"  class="align-center mx-3 mt-2 font-weight-light" style="">
                          <div align="left" class="mt-5 font-weight-bold" style="font-weight: 900; font-size: 140%; line-height: 1; max-width: 550px;">Disrupting <br> digital ID</div>
                          <div class="mx-0 my-5" style="background-color: #ffffff88; backdrop-filter: blur(30px); width: 100%; max-width: 550px; height: 20px "> </div>
                          <div align="left" style="line-height: 100%; font-weight: 400; font-size: 50%; max-width: 550px;" class="mt-2">
                            Decentralized technology to ensure protection and privacy. iD4Good is the foundation and standard for today’s security and tomorrow’s post quantum security risks
                          </div>
                          <div align="center" style="max-width: 550px;" class="mt-10">
                            <v-img  rel="preload" align="center" :src="require('@/assets/logos/id4good-white.svg')" max-width="350px" ></v-img>
                          </div>
                          <div align="center" style="max-width: 550px;" class="mt-10">
                            <v-btn  rounded color="#17D1FF" class="mx-5 my-5 pa-5 white--text" to="/id4good" text-color="#ffffff">Learn More</v-btn>
                            <v-btn  rounded color="#6FA2AF" class="mx-5 my-5 pa-5 white--text" to="/contact">Contact Us </v-btn>
                          </div>
                        </div>
                      </v-col>
                      <v-col align-self="left" align="left">
                      </v-col>
                    </v-row>
                  </v-container>
                </v-img>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent :custom-style="{ border: '0' }" fluid class="">
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/wallet-background.jpg')" width="100%" height="70vh" style="position: relative;">
                  <v-container fluid class="fill-height" style="color: white">
                    <v-row dense>
                      <v-col align-self="left" align="left">
                      </v-col>
                      <v-col cols="12" md="6" align-self="center" align="">
                        <div class="px-5 font-weight-light" style="
                          align-self: self-end;
                          align-items: end;
                          position: absolute;
                          top: 0;
                          right: 0;
                          width: 50%;
                          height: 100%;
                          min-width: 400px;
                          background: #044D4A;
                          opacity: 0.5;
                          z-index: -1;
                          "></div>
                        <div align="left"  class="align-center mx-4 mt-2 font-weight-light" style="">
                          <div align="left" class="mt-5" style="font-weight: 900; font-size: 140%; line-height: 1; max-width: 550px;">Asset management</div>
                          <div class="mx-0 my-5" style="background-color: #ffffff88; backdrop-filter: blur(30px); width: 100%; max-width: 550px; height: 20px "> </div>
                          <div align="left" style="line-height: 100%; font-weight: 400; font-size: 50%; max-width: 550px;" class="mt-2">
                            Wallet4Good is a groundbreaking self-custody digital asset management friend, a hyper secure and feature-rich web application that can become your digital life vault.
                          </div>
                          <div align="center" style="max-width: 550px;" class="mt-10">
                            <v-img  rel="preload" align="center" :src="require('@/assets/logos/wallet4good-white.svg')" max-width="350px" ></v-img>
                          </div>
                          <div align="center" style="max-width: 550px;" class="mt-10">
                            <v-btn  rounded color="#17D1FF" class="mx-5 my-5 pa-5 white--text" to="/pay4global" text-color="#ffffff">Learn More</v-btn>
                            <v-btn  rounded color="#6FA2AF" class="mx-5 my-5 pa-5 white--text" to="/contact">Contact Us </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-img>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>

        <v-row dense v-if="false">
          <v-col>
            <BannerSingleDarkComponent :custom-style="{ border: '0' }" fluid class="mb-3">
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/community-background.jpg')" width="100%" height="70vh" style="position: relative;">
                  <v-container fluid class="fill-height" style="color: black">
                    <v-row dense>
                      <v-col cols="12" md="6" align-self="center" align="">
                        <div align="left" class="px-5 font-weight-light" style="
                          position: absolute;
                          top: 0;
                          left: 0%;
                          width: 50%;
                          height: 100%;
                          background: #ffffff;
                          min-width: 400px;
                          opacity: 50%;
                          z-index: -1;
                          "></div>
                        <div align="right"  class="align-center mx-3 mt-2 font-weight-light" style="">
                          <div align="left" class="mt-5 font-weight-bold" style="color: #003D8C; font-weight: 900; font-size: 140%; line-height: 1; max-width: 550px;">Community engage</div>
                          <div class="mx-0 my-5" style="background-color: #17D1FF88; backdrop-filter: blur(30px); width: 100%; max-width: 550px; height: 20px "> </div>
                          <div align="left" style="line-height: 100%; font-weight: 400; font-size: 50%; max-width: 550px;" class="mt-2">
                            As part of the Pay4.Global platform, Pay4Good shares all its cutting edge technology with communities, helping local merchants to raise funds for important community empowerment. A method payment for Goodty risks
                          </div>
                          <div align="center" style="max-width: 550px;" class="mt-10">
                            <v-img  rel="preload" align="center" :src="require('@/assets/logos/pay4good-blue.svg')" max-width="350px" ></v-img>
                          </div>
                          <div align="center" style="max-width: 550px;" class="mt-10">
                            <v-btn  rounded color="#17D1FF" class="mx-5 my-5 pa-5 white--text" to="/pay4good" text-color="#ffffff">Learn More</v-btn>
                            <v-btn  rounded color="#6FA2AF" class="mx-5 my-5 pa-5 white--text" to="/contact">Contact Us </v-btn>
                          </div>
                        </div>
                      </v-col>
                      <v-col align-self="left" align="left">
                      </v-col>
                    </v-row>
                  </v-container>
                </v-img>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>

        <v-row dense v-if="false">
          <v-col>
            <NotifyComponent>
              <template v-slot:content>
                Let's <strong>Create</strong> Together
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense v-if="false">
          <v-col>
            <BannerSingleComponent style="background-color: #EFF1F6">
              <template v-slot:content>
                <v-container fluid class="fill-height" style="max-width: 1200px">
                  <v-row dense>
                    <v-col align-self="center" align="center" cols="12" md="6" class="hidden-md-and-down">
                      <v-img :src="require('@/assets/DesktopC.png')" contain max-height="55vh" />
                    </v-col>
                    <v-col align-self="center" align="center">
                      <div align="right" style="position: relative; right: 5%; top: 40px">
                        <v-img  :src="require('@/assets/deptofgoodLogo2.svg')" contain max-width="100px" />
                      </div>
                      <div align="left" style="position: relative; top: -50px;">
                        <div class="font-weight-light ml-10" style="font-size: clamp(32px, 5vw, 64px); line-height: 100%">Build.</div>
                        <div class="font-weight-bold ml-10" style="font-size: clamp(32px, 5vw, 64px); line-height: 100%">Empower</div>
                        <div class="font-weight-light ml-10" style="font-size: clamp(32px, 5vw, 64px); line-height: 100%">Grow</div>
                        <div class="font-weight-light font-italic mt-5 px-10" align="left" style="font-size: clamp(18px, 3vw, 24px); line-height: 100%">
                          Dept. of Good blends physical assets, digital assets like NFTs and hybrid assets. With Dept of Good; SMEs individuals, artists and creators are invited to build their personal models on our platform. A variety of templates will be offered which will include virtual reality, one click buying and many more features.
                          <br><br>
                          Coming 1st QTR 2023
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense v-if="false">
          <v-col>
            <CompareComponent />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="mx-1 mt-0">
            <ProductsBoxComponent />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <BannerSingleComponent height="70vh" py-0 my-0>
              <template v-slot:content>
                <v-container fluid class="fill-height">
                <v-row dense class="px-0 mx-0" align="center">
                  <v-col cols="12" class="px-0 mx-0" style="text-align: center">
                    <div class="px-5 font-weight-light" style="text-align: center; height: 70vh; background: transparent linear-gradient(180deg, #DDFAFF 0%, #FFFFFF 100%) 0% 0%;">
                      <v-row align="center">
                        <v-col align="center">
                          <div class="pt-5 mt-15">
                            <v-img rel="preload" :src="require('@/assets/logos/pay4global-blue.svg')" max-width="400px"></v-img>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col align="center">
                          <div style="text-align: center; font-size: clamp(16px, 3vw, 30px);">
                            One platform able to be customized one by one
                          </div>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col align="center">
                          <div>
                            <v-btn rounded color="#17D1FF" class="mx-5 my-5 white--text pa-5" to="/pay4global" text-color="#ffffff">Learn More</v-btn>
                            <v-btn  rounded color="#6FA2AF" class="mx-5 my-5 white--text pa-5" to="/contact">Contact Us </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row align="center" style="align-items:center">
                        <v-col cols="2" align="center" class="px-1">
                          <v-img :src="require('@/assets/devices/p4g-iPhone.png')" contain max-height="200px"></v-img>
                        </v-col>
                        <v-col cols="3" align="center" class="px-1">
                          <v-img :src="require('@/assets/devices/p4g-Laptop.png')" contain max-height="200px"></v-img>
                        </v-col>
                        <v-col cols="2" align="center" class="px-1">
                          <v-img :src="require('@/assets/devices/p4g-Android.png')" contain max-height="200px"></v-img>
                        </v-col>
                        <v-col cols="3" align="center" class="px-1">
                          <v-img :src="require('@/assets/devices/p4g-Desktop.png')" contain max-height="200px"></v-img>
                        </v-col>
                        <v-col cols="2" align="center" class="px-1">
                          <v-img :src="require('@/assets/devices/p4g-POS.png')" contain max-height="200px"></v-img>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>

        <v-row dense v-if="false">
          <v-col>
            <BannerSingleComponent>
              <template v-slot:content>
                <div class="my-15">
                  <strong>Ready</strong> for the Future <strong>Now !!!</strong>
                </div>
                <div class="text-h6 font-weight-light font-italic" style="padding: 0 20%">
                  <p>We are proud to introduce a fully integrated decentralized ecosystem blending multiple technologies and ready for digital transformation. Built to empower the peer2peer sharing economy, B4G is ready for mass adoption, as it was built for bridging the gap between the analogue and digital seamlessly.</p>
                  <p>We offer a rare opportunity for SMEs and individuals to enter a technology revolution early, with cost effectiveness, flexibility and a rare opportunity to increase revenues on a level playing field.</p>
                </div>
                <div class="my-15">
                  <v-btn dark x-large class="px-10 py-8 Button" color="#2D3843">View More</v-btn>
                </div>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense v-if="false">
          <v-col>
            <BannerSingleDarkComponent>
              <template v-slot:content>
                <div class="my-15 py-15">
                  <strong>Join Us in</strong> Pioneering the <strong>Good Economy</strong>
                </div>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense v-if="false">
          <v-col>
            <BannerSingleComponent>
              <template v-slot:content>
                <v-container class="my-10">
                  <v-row>
                    <v-col align-self="center" align="center" cols="12" md="6">
                      <v-img :src="require('@/assets/pay4good.svg')" height="100px" max-width="450px" contain />
                    </v-col>
                    <v-col align-self="center" align="center" style="font-size: 1.5rem;" cols="12" md="6">
                      <strong>Lorem</strong> Ipsum <strong>Dolor</strong>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-img class="my-2" :src="require('@/assets/products/Pay4Good.png')" max-width="80%" contain />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :align="$vuetify.breakpoint.smAndDown ? 'center' : 'right'">
                      <v-img :src="require('@/assets/google-play-badge.png')" height="50px" max-width="250px" contain />
                    </v-col>
                    <v-col :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left'">
                      <v-img :src="require('@/assets/apple-store-badge.svg')" height="50px" max-width="250px" contain />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>

        <v-row dense v-if="false">
          <v-col>
            <PartnersComponent />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_HomeView.scss'
import CompareComponent from '@/components/CompareComponent/CompareComponent'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import ProductsBoxComponent from '@/components/ProductsBoxComponent/ProductsBoxComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import PartnersComponent from '@/components/PartnersComponent/PartnersComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import meta from '@/mixins/meta'
export default {
  name: 'HomeView',
  components: { NotifyComponent, PartnersComponent, BannerSingleDarkComponent, ProductsBoxComponent, BannerSingleComponent, CompareComponent },
  mixins: [meta],
  data () {
    return {
    }
  },
  mounted () {
    const scale = this.$refs.bannerRotor.clientHeight / 380
    this.$refs.bannerRotor.style.setProperty('transform', 'scale(' + scale + ')')
    console.log(scale)
  },
  updated () {
    const scale = this.$refs.bannerRotor.clientHeight / 380
    this.$refs.bannerRotor.style.setProperty('transform', 'scale(' + scale + ')')
    console.log(scale)
  }
}
</script>
