<template>
    <div class="OurPartnersView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">We welcome partners and collaborators to join us, there is much we can do together Let's CHAT!</span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/partners/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15 py-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/partners/partners.svg')" max-width="369px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          We are passionate to help individuals, SMEs and organizations move towards the new digital economy. We believe that future technology must be democratized and available to all. We are interested in supporting and partnering up with innovative business models that also believe in empowering the community. Do you have an idea? let’s Chat
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>LEt’s chat</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px;">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/partners/01.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/partners/02.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height my-15">
                  <v-row>
                    <v-col align-self="center" align="center" style="font-size: 200%; line-height: 100%">
                        <div>Interested <span class="font-weight-bold">in partnering</span></div>
                      <div><span class="font-weight-bold">with us</span> on a project?</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large >Let’s CHAT</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense ref="scroll[8]">
          <v-col>
            <PartnersComponent />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_OurPartnersView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import PartnersComponent from '@/components/PartnersComponent/PartnersComponent'
export default {
  name: 'OurPartnersView',
  components: { PartnersComponent, BannerTwoComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
