<template>
    <div class="BannerSingleDarkComponent">
      <v-container fluid class="Container pa-0" :style="customStyle">
        <v-row dense style="min-height: 150px">
          <v-col align-self="center" align="center" :class="{ SingleBox: true, Banner: true, fluid: fluid }">
            <slot name="content">
              <div align="center">
                <div v-if="title" v-html="title" class="text-h3 mt-10 font-weight-bold"></div>
                <div v-if="subtitle" v-html="subtitle" class="text-h5 font-weight-regular"></div>
                <div class="my-10">
                  <v-img v-if="img" :src="img" contain width="80%" max-width="700px" class="mb-10" />
                </div>
              </div>
            </slot>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_BannerSingleDarkComponent.scss'

export default {
  name: 'BannerSingleDarkComponent',
  props: {
    title: String,
    subtitle: String,
    img: String,
    to: String,
    fluid: Boolean,
    customStyle: Object
  }
}
</script>
