<template>
    <div class="Sandbox4GoodView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">We invite all developers and potential partners to join us in developing innovative ideas</span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/sandbox/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/sandbox/sandbox.svg')" max-width="300px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Our Sandbox development Kit, will offer unprecedented flexibility in accessing our decentralized infrastructure. We encourage all who want to develop innovation to join us and create Apps integrated onto the 4GoodNetwork. We are compatible with Node.js runtime.
                          We believe collaboration and revenue sharing fairly is the best roadmap to grow our network. Also when you develop any application you have the ability to have all the important components (ID, Wallet, Payment) easily integrated on any solution.
                          Join us in our vision to empower humanity.</div>
                        <div style="max-width: 350px">
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          Bring new apps and services to life with all the advantages our 4GoodNetwork delivers, get the freedom of choice with Pay4Good, the safety and security of Wallet4Good, the power of iD4Good and mix them with your idea to create new experiences for users, where trust, traceability, peer to peer, privacy and  are the core. We put at your disposal all the necesary tools to make great and original creations that could benefit millions.</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact us</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/iD4Good.svg')" aspect-ratio="1" contain width="48px" />iD4Good</template>
              <template slot="Content">One key to unlock all with your iD4Good, all starts here!</template>
              <template slot="Link">Get the app</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/Pay4Good.svg')" aspect-ratio="1" contain width="48px" />Pay4Good</template>
              <template slot="Content">Pay everywhere, anytime, and contactless using Pay4Good.</template>
              <template slot="Link">Contact us</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/Wallet4Good.svg')" aspect-ratio="1" contain width="48px" />Wallet4Good</template>
              <template slot="Content">Freely manage your digital assets and cryptos with Wallet4Good.</template>
              <template slot="Link">Go Wallet4Good.com</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/DeptOfGood.svg')" aspect-ratio="1" contain width="48px" />Dept. of Good</template>
              <template slot="Content">Create, showcase, share, explore, sell and buy with Dept. of Good.</template>
              <template slot="Link">Go DeptOfGood.com</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">05</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/Box4Good.svg')" aspect-ratio="1" contain width="48px" />Box4Good</template>
              <template slot="Content">Boost, store, earn, accept payments, participate with Box4Good.</template>
              <template slot="Link">Request your box</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">06</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/4GoodNetwork.svg')" aspect-ratio="1" contain width="48px" />Network4Good</template>
              <template slot="Content">Participate and earn in the 4GoodNetwork with Node4Good.</template>
              <template slot="Link">Contact us</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">07</template>
              <template slot="Title">
                <div>
                  <div class="mb-1"><v-img class="mr-2 d-inline-flex" :src="require('@/assets/icons/iconboxes/Apta4Goos.svg')" aspect-ratio="1" contain width="48px" style="vertical-align: top" />Apta4Good</div>
                  <div><v-img class="mr-2 d-inline-flex" :src="require('@/assets/icons/iconboxes/Token4Good.svg')" aspect-ratio="1" contain width="48px" style="vertical-align: top" />Token4Good</div>
                </div>
              </template>
              <template slot="Content">Buy, sell, pay, earn using our Token4Good and Apta4Good.</template>
              <template slot="Link">Know more</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">07</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/comfortable.svg')" aspect-ratio="1" contain width="48px" />News</template>
              <template slot="Content">And there is a lot much more to come! Stay tuned.</template>
              <template slot="Link">Suscribe for news</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/sandbox/01.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/sandbox/02.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                        <div>Think, create and <span class="font-weight-bold">run</span>Privacy</div>
                      <div><span class="font-weight-bold">decentralized</span></div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="min-height: 60vh" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">A technical <strong>touch</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10" align="left">
                          SandBox runs dApp on Node.js runtime, using its API to access client wallet functionality without compromising privacy and security. Those applications are stored fully encrypted in the blockchain’s InterPlanetary File System (IPFS), which means the user gets the application and it is created as an NFT and then it runs associated with the user’s wallet and; the same the wallet does; on virtually all types of devices, because all code runs directly in the user’s web browser, and the app requires only a modern enough web browser.                        </div>
                        <div style="max-width: 350px">
                          <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Fully decentralized</template>
              <template slot="Content"><ul><li>dApps are stored in the Blockchain InterPlanetary System (IPFS).</li>
                <li>Code runs on user Wallet</li></ul></template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-icon class="mr-2">mdi-hand-extended-outline</v-icon>Accesible</template>
              <template slot="Content"><ul><li>No need to learn new programming languages, Your current Node.js runtime skills are welcome.</li>
                <li>dApps can run in virtually all types of devices that support relatively modern web browsers.</li></ul></template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-icon class="mr-2">mdi-rocket-launch-outline</v-icon>Powerful</template>
              <template slot="Content"><ul><li>Build amazing dApps with all the tools we have for you, including Pay4good, Wallet4Good,  iD4Good, and all the forthcoming new features we will develop.</li>
                <li>Create new experiences in the most affordable decentralized network ever built.</li></ul></template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-icon class="mr-2">mdi-shield-check-outline</v-icon>Secure for all</template>
              <template slot="Content"><ul><li>Thanks to iD4Good and the safety mechanism embedded in the 4GoodNetwork, user and developer privacy are never compromised.</li>
                <li>AdApps run associated to user wallet as an NFT.</li></ul></template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Roles that <strong>fit</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Every kind of user takes a role in the 4GoodNetwork depending on the needs or activity they do, so our infrastructure is able to compensate each of them properly, in example, if you are a developer you can have an App Owner or Service Owner role, and get the specified compensation for your app without affecting the other roles on the network that indeed work to keep your app or service running. Partner roles can leverage to distribute existing resources and also receive compensation. Therefore, each new addition to the network makes all other roles more active, specially those related to the Core Network Platform; who deliver the transaction process; and each role contributions is adequately rewarded.</div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/node4good/banner3.png')" width="100%" height="80vh" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="min-height: 60vh" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Be part <strong>now</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10" align="left">
                          When you identify yourself using iD4Good, or you pay using Pay4Good, or you mint an NFT using Wallet4Good or Dept. of Good you are actively participating as user, but if you want to go further, get in touch with us and get an active role that fits your expectations. We have subscription plans to be a Node Founder with virtual Node4Good, or you can get our Node4Good hardware and start holding the network in your location.
                        </div>
                        <div style="max-width: 350px">
                          <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Get iD4Good, <strong>start now</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Sandbox4GoodView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'

export default {
  name: 'Sandbox4GoodView',
  components: { BannerTwoComponent, SimpleInfoBoxComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
