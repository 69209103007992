<template>
  <div class="PayGoodView">
    <v-container fluid class="pa-0">
      <v-row dense>
        <v-col>
          <NotifyComponent class="slide-in-top">
            <template slot="content">
              <span style="font-size: 0.9rem">Pay4Good is perfectly supported in our Box4Good hardware</span>
              <span style="font-size: 0.9rem" class="mx-5"><a href="/box4good">Box4Good</a></span>
            </template>
          </NotifyComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
            <template v-slot:content>
              <v-img rel="preload" :src="require('@/assets/pay4good/banner.png')" width="100%" height="80vh" position="right center">
                <div :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'" style="background: linear-gradient(270deg, rgba(29,71,112,0) 0%, rgba(29,71,112,0) 50%); height: 100%; width: 100%">
                  <div style="position: relative; top: 30%; right: 10%;" v-if="$vuetify.breakpoint.mdAndUp">
                    <div align="center" style="max-width: 35vw">
                      <v-img class="mb-3" max-width="33vw" :src="require('@/assets/pay4good/p4g.svg')"></v-img>
                      <div class="white--text font-weight-light" style="font-size: 36px; line-height: 100%">Most comprehensive omnipayment platform solution available compatible with 250 payment options</div>
                    </div>
                  </div>
                  <div style="position: relative; top: 30%;" v-if="!$vuetify.breakpoint.mdAndUp">
                    <div align="center" style="max-width: 300px">
                      <v-img class="mb-3" max-width="290px" :src="require('@/assets/pay4good/p4g.svg')"></v-img>
                      <div class="white--text font-weight-light" style="font-size: 18px; line-height: 100%">Most comprehensive omnipayment platform solution available</div>
                    </div>
                  </div>
                </div>
              </v-img>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
            <template v-slot:content>
              <v-container class="my-15">
                <v-row>
                  <v-col align-self="center" align="center">
                    <div>
                      <v-img :src="require('@/assets/pay4good/pay_logo.svg')" max-width="300px"></v-img>
                      <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                        Pay4Good offers a comprehensive payment solution built for the new programmable (digital) economy. Able to accept credit cards, digital payments, and ready for crypto and tokens instantly. Innovative ability to be programmed user by user.</div>
                      <div style="max-width: 350px">
                        <v-row>
                          <v-col>
                            <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Join Us</v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleDarkComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid>
            <template v-slot:content>
              <v-container style="max-width: 1200px" class="my-10">
                <v-row>
                  <v-col>
                    <VideoBannerComponent :src="require('@/assets/videos/pay4good.mp4')" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <div style="font-size: 1.4rem; line-height: 150%">
                      Using blockchain and other decentralized technologies, P4G is safe and post-quantum computing secure. P4G is able to manage billions of transactions while guarding traceability for any type of payment.</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align-self="center" align="center">
                    <v-container>
                      <v-row>
                        <v-col>
                          <div style="width: 200px">
                            <div>
                              <v-img :src="require('@/assets/icons/iconboxes/versatile2.svg')" aspect-ratio="1" width="48px"></v-img>
                            </div>
                            <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                              Versatile
                            </div>
                            <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                              Our OmniPayment processor and gateway has the ability to empower your business and bring it to the next level reaching billions of potential new customers.
                            </div>
                          </div>
                        </v-col>
                        <v-col>
                          <div style="width: 200px">
                            <div>
                              <v-img :src="require('@/assets/icons/iconboxes/secure.svg')" aspect-ratio="1" width="48px"></v-img>
                            </div>
                            <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                              Secure
                            </div>
                            <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                              Fully reliable and trustable decentralized blockchain technologies, ensuring rock-solid transactions management.
                            </div>
                          </div>
                        </v-col>
                        <v-col>
                          <div style="width: 200px">
                            <div>
                              <v-img :src="require('@/assets/icons/iconboxes/nft.svg')" aspect-ratio="1" width="48px"></v-img>
                            </div>
                            <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                              Flexible
                            </div>
                            <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                              Our solution is more than mobile App only, is a complete platform with amazing functionalities running in our ecosystem, able to fit in any device or requirement.
                            </div>
                          </div>
                        </v-col>
                        <v-col>
                          <div style="width: 200px">
                            <div>
                              <v-img :src="require('@/assets/icons/iconboxes/powerful.svg')" aspect-ratio="1" width="48px"></v-img>
                            </div>
                            <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                              Powerful
                            </div>
                            <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                              A growing market of cryptocurrencies, digital wallets, digital payment methods and classic credit and debit cards allowed.
                            </div>
                          </div>
                        </v-col>
                        <v-col>
                          <div style="width: 200px">
                            <div>
                              <v-img :src="require('@/assets/icons/iconboxes/comfortable.svg')" aspect-ratio="1" width="48px"></v-img>
                            </div>
                            <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                              Comfortable
                            </div>
                            <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                              Frictionless, contactless, Painless. Always fast and secure for both edges
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align="center">
          <BannerTwoComponent fluid style="max-width: 2048px">
            <template v-slot:FirstBox>
              <v-img rel="preload" :src="require('@/assets/pay4good/iphone.png')" width="100%" height="100%" max-height="60vh">
              </v-img>
            </template>
            <template v-slot:SecondBox>
              <v-img rel="preload" :src="require('@/assets/pay4good/20.png')" width="100%" height="100%" max-height="60vh">
              </v-img>
            </template>
          </BannerTwoComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleDarkComponent fluid style="background-color: #1D4770">
            <template v-slot:content>
              <v-container style="min-height: 300px" class="fill-height my-15">
                <v-row>
                  <v-col align-self="center" align="center" style="font-size: 120%; line-height: 100%">
                    <div>Accept payments, and <span class="font-weight-bold"><FONT COLOR="#6EACE8">Pay Fast</FONT> anywhere with</span><span class="font-weight-bold"><FONT COLOR="#6EACE8"> Security, and Privacy</FONT></span> with every imaginable
                      option and<span class="font-weight-bold"> be ready for<FONT COLOR="#6EACE8"> the future</FONT></span></div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleDarkComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid>
            <template v-slot:content>
              <v-container style="min-height: 30vh" class="fill-height">
                <v-row>
                  <v-col align-self="center" align="center">
                    <div>
                      <div style="font-size: 150%"><FONT COLOR="#6EACE8">All in</FONT> <strong><FONT COLOR="#1D4770">One Solution</FONT></strong></div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
            <template v-slot:content>
              <v-img rel="preload" :src="require('@/assets/pay4good/banner1.png')" width="100%" height="100vh" position="right center">
                <div :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'">
                  <div style="position: relative; right: 10%;" v-if="$vuetify.breakpoint.mdAndUp">
                    <div align="center" style="max-width: 35vw">
                      <div style="font-size: 120%"><strong><FONT COLOR="#6EACE8">What makes </FONT></strong><FONT COLOR="#1D4770">Pay4Good </FONT><strong><FONT COLOR="#1D4770">Unique???</FONT></strong></div><br>
                      <div style="font-size: 1.2rem; font-weight: normal"><FONT COLOR="#1D4770">Pay4Good is a Web 3.0 payment platform that opens limitless advantages both for business and consumers. We are sure consumers will be encouraged by the ease of use and opportunities to spend their crypto (or any of their other options).<br><br> We enable and facilitate your business to accept  new and exciting options like crypto, local tokens, cc and digital options all in one solution.<br><br> We designed our technology to empower the new peer2pper programmable economy in the most efficient and cost effective way.</FONT></div><br>
                      <v-col>
                        <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" x-large>Contact Us</v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-img>
            </template>
          </BannerSingleComponent>
          <v-row dense ref="scroll[8]">
            <v-col>
              <DevicesComponent />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">01</template>
            <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Control & Analytics</template>
            <template slot="Content">As business you should have complete control of your income. We have incorporated in Pay4Good a complete professional dashboard, where you can configure different settings and get full information and analytics at a glance.</template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">02</template>
            <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Flexibility</template>
            <template slot="Content">Imagine any application where a tool like Pay4Good can help your business be more competitive, we are ready to do that.</template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">03</template>
            <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Mobility</template>
            <template slot="Content">Is your business large or spread? Pay4Good can be cloned to 2.500 devices of any kind, so your taxi service or delivery business will be perfectly covered anywhere.</template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">04</template>
            <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Our Boxes</template>
            <template slot="Content">Meet our Box4Good hardware, its different versions offer unparalleled reliability and the chance to deploy optional features.</template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">04</template>
            <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Open System</template>
            <template slot="Content">Open system able to accept FIAT, CC, Cash Systems, Crypto and Digital payments.</template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">04</template>
            <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Community</template>
            <template slot="Content">Able to accommodate a localized tokenized economy community by community.</template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">04</template>
            <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Wearable</template>
            <template slot="Content">Hardware, Software, smart phones, tablets, kiosks, e-commerce, wearables.</template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <SimpleInfoBoxComponent>
            <template slot="Index">04</template>
            <template slot="Title"><v-icon class="mr-2">mdi-star-outline</v-icon>Connection</template>
            <template slot="Content">Can be connected to banking applications connected to your Wallet4Good (if desired).</template>
            <template slot="Button"><span></span></template>
          </SimpleInfoBoxComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
            <template v-slot:content>
              <v-img rel="preload" :src="require('@/assets/pay4good/banner6.png')" width="100%" height="100vh" position="right center">
                <div :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'">
                  <div style="position: relative; top: 150px; right: 60%;" v-if="$vuetify.breakpoint.mdAndUp">
                    <div align="center" style="max-width: 35vw">
                      <div style="font-size: 120%"><strong><FONT COLOR="#6EACE8">Power failure? </FONT></strong></div><br>
                      <div style="font-size: 1.2rem; font-weight: normal"><FONT COLOR="#1D4770">Pay4Good is never down, it has the resilience to be up and running in seconds on any device that supports a modern web browser. It makes sure your business does not lose ONE SALE !!!
                        Pay4Good’s resiliency is made possible by the latest cutting edge technology including blockchain.<br></FONT></div><br>
                      <v-col>
                        <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Join Us</v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-img>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleDarkComponent fluid style="background-color: #1D4770">
            <template v-slot:content>
              <v-container style="min-height: 300px" class="fill-height my-15">
                <v-row>
                  <v-col align-self="center" align="center">
                    <div style="font-size: 150%">We can go offline <FONT COLOR="#6EACE8">to get all</FONT> <strong><FONT COLOR="#6EACE8"> customers</FONT></strong> <strong> online</strong></div>
                    <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                      Pay4Good is the only payment system that can accommodate e-commerce in emerging economies where the vast majority of the population is Unbanked and Underbanked where CC and DC are not owned and connectivity is limited.</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleDarkComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid>
            <template v-slot:content>
              <v-container style="min-height: 25vh" class="fill-height">
                <v-row>
                  <v-col align-self="center" align="center">
                    <div>
                      <div style="font-size: 150%"><FONT COLOR="#6EACE8">Our</FONT> <strong><FONT COLOR="#1D4770">Box4Good</FONT></strong></div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
            <template v-slot:content>
              <v-img rel="preload" :src="require('@/assets/pay4good/banner3.png')" width="100%" height="100%" position="top center">
              </v-img>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
            <template v-slot:content>
              <v-img rel="preload" :src="require('@/assets/pay4good/banner4.png')" width="100%" height="90vh" position="right center">
                <div :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'">
                  <div style="position: relative; top: 75px; right: 10%;" v-if="$vuetify.breakpoint.mdAndUp">
                    <div align="center" style="max-width: 35vw">
                      <div style="font-size: 69%"><strong><FONT COLOR="#FFFFFF">Expand revenue Streams for your</FONT></strong></div>
                      <div style="font-size: 240%"><strong><FONT COLOR="#6EACE8"> Business</FONT></strong></div>
                      <div style="font-size: 1.2rem; font-weight: normal"><FONT COLOR="#ffffff">The future of web 3.0 and the programmable economy will open many opportunities for business.<br><br>Today your business may be accepting legacy payment options, P4G opens the future now. By working with P4G you are able to add and remove payment options easily through your own dashboard, making localized options a reality.<br><br>Imagine the possibilities of growing revenue streams with you in control at all times. Pay4Good enables this and more, including loyalty programs and charitable donations at check out.<br><br>Take a leap into the future with Pay4good.</FONT></div>
                    </div>
                  </div>
                </div>
              </v-img>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
            <template v-slot:content>
              <v-container class="my-15">
                <v-row>
                  <v-col align-self="center" align="center">
                    <div>
                      <div style="font-size: 150%">Is <strong>e-commerce </strong>part of your business <strong>model? </strong></div>
                      <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                        Our Payment Platform is easily integrated into your e-commerce solution and is ready for the NFT landscape and the Web 3.0 Market Networks.</div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Find Out More</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleDarkComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
            <template v-slot:content>
              <v-img rel="preload" :src="require('@/assets/pay4good/banner5.png')" width="100%" height="100vh" position="right center">
                <div style="position: relative; top: 30%;" v-if="$vuetify.breakpoint.mdAndUp">
                  <div align="center">
                    <div style="font-size: 240%"><FONT COLOR="#FFFFFF">We build<FONT COLOR="#6EACE8"> dedicated </FONT><br></FONT><strong><FONT COLOR="#6EACE8"> hardware </FONT></strong><FONT COLOR="#FFFFFF">too</FONT></div>
                  </div>
                </div>
              </v-img>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid>
            <template v-slot:content>
              <v-container style="min-height: 25vh" class="fill-height">
                <v-row>
                  <v-col align-self="center" align="center">
                    <div>
                      <div style="font-size: 150%"><FONT COLOR="#6EACE8">It’s just pay<br>
                      </FONT> <strong><FONT COLOR="#1D4770">But it has even more</FONT></strong></div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <v-container>
            <v-row>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/versatile2.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    Peace of mind
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    All transactions are secured by blockchain proprietary patented technology
                  </div>
                </div>
              </v-col>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/secure.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    Jaw-dropping options!
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    We easily can include any of the growing market of digital payments and cryptos. You decide which to accept
                  </div>
                </div>
              </v-col>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/nft.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    Fast, simple and precise
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    Our network can process billions of transactions and is the fastest and more advanced
                  </div>
                </div>
              </v-col>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/powerful.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    Up to date
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    All our software applications are permanently deploying the latest version available automatically
                  </div>
                </div>
              </v-col>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/powerful.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    Enable
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    AEnabled for loyalty programs.
                    Enabled for charitable donations
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <v-container>
            <v-row>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/versatile2.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    High capacity and growing
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    Our 4GoodNetwork can process blockchain transactions more than 3X the fastest market network, and virtually 85kX Bitcoin network
                  </div>
                </div>
              </v-col>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/secure.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    A class by itself
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    Pay4Good is the only OmniPayment solution giving you all options in just one packag
                  </div>
                </div>
              </v-col>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/nft.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    Pay4Good can coexist
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    You don’t need to replace your current systems, Pay4God can be easily integrated
                  </div>
                </div>
              </v-col>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/powerful.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    We support other methods
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    Prepaid? Deferred payments? Gift cards? Top up with cash? Surely we can manage them for you
                  </div>
                </div>
              </v-col>
              <v-col>
                <div style="width: 200px">
                  <div>
                    <v-img :src="require('@/assets/icons/iconboxes/powerful.svg')" aspect-ratio="1" width="48px"></v-img>
                  </div>
                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                    Compatible
                  </div>
                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                    Compatible with multiple Blockchain networks and majority of wallets
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleDarkComponent fluid style="background-color: #1D4770">
            <template v-slot:content>
              <v-container style="min-height: 300px" class="fill-height my-15">
                <v-row>
                  <v-col align-self="center" align="center">
                    <div style="font-size: 150%">The <FONT COLOR="#6EACE8">crypto </FONT>owner <strong><FONT COLOR="#6EACE8"> Paradox</FONT></strong></div>
                    <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                      Cryptocurrencies… easy to buy, good to have, so good to see them increase in value, but so hard to spend in the real world… with P4G cryptos and tokens can easily be used to purchase from your favorite merchant. Tell your favorite stores to contact us and become an embassador.</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleDarkComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid>
            <template v-slot:content>
              <v-container style="max-width: 1200px" class="my-10">
                <v-row>
                  <v-col>
                    <div style="font-size: 150%">
                      Get iD4Good, <strong>start now</strong>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div style="font-size: 1.4rem; line-height: 150%">
                      Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.
                    </div>
                  </v-col>
                </v-row>
                <v-row class="my-10">
                  <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                    <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                    <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                      <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                    </v-btn>
                  </v-col>
                  <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                    <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                    <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                  </v-col>
                  <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                    <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                    <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                      <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_Pay4GoodView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'
import DevicesComponent from '@/components/DevicesComponent/DevicesComponent'
export default {
  name: 'Pay4GoodView',
  components: { DevicesComponent, SimpleInfoBoxComponent, BannerTwoComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
