<template>
  <div class="Pay4GlobalView">
    <v-container fluid class="pa-0">
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid :custom-style="{ border: '0' }">
            <template v-slot:content>
              <v-img rel="preload" :src="require('@/assets/pay4global/banner.jpg')" width="100%" height="60vh" position="right center">
              </v-img>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid style="background-color: #ffffff">
            <template v-slot:content>
              <v-container class="my-15">
                <v-row>
                  <v-col align="right">
                    <v-img :src="require('@/assets/pay4global/pay_logo.svg')" max-width="500px"></v-img>
                  </v-col>
                  <v-col align-self="center" align="left" style="font-size: 80%">
                    <div style="max-width: 500px" class="mx-2">
                    A groundbreaking Decentralized Economy Platform built for today and ready for the future. As a B2B platform P4.G allows easy and profitable penetration into B2C also through partnerships. <br><br>P4G is versatile, and integration can be tailored to all types of business models individually one by one.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid style="background-color: #f7f9fc">
            <template v-slot:content>
              <v-container style="font-size: 70%; max-width: 1600px" class="pa-15">
                <v-row class="py-10">
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon01.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Self-Custody Digital Asset Management</div>
                    <div style="color: #707070">
                      Empowers users to manage and control their digital assets without relying on 3rd parties. Full security using ID4Good with patent pending technology which readies the wallet for the future of the digital world.
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon02.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Self-Custody Identity and Access Management</div>
                    <div style="color: #707070">
                      Facilitates individuals and companies to manage their personal identity, DATA confidentially, ensuring privacy and security
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon03.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Processes Traditional Payment Methods, Digital Payments, Crypto Currencies (where legal)</div>
                    <div style="color: #707070">
                      All forms of payment are handled on a single platform in a single point of integration creating ease of scalability, efficiencies, and cost-effectiveness
                    </div>
                  </v-col>
                </v-row>
                <v-row class="py-10">
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon04.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Invoicing capabilities for SMEs</div>
                    <div style="color: #707070">
                      Generate and send invoices directly, simplifying transaction process for businesses as well as freelancers, giving important tools for the gig- economy. Buy now pay later, micro-financing all through the payment network
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon05.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Loyalty Programs tailor made one merchant at a time</div>
                    <div style="color: #707070">
                      Enhances Customer Loyalty with Rewards: Engage and retain users by providing loyalty points, gift cards, and more. Opening up referrals and improved profitability as well as new revenue streams
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon06.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">QuickBooks integration for SME management</div>
                    <div style="color: #707070">
                      Will enable SMEs to operate efficiently including affecting inventories and DATA driven decision making on the go
                    </div>
                  </v-col>
                </v-row>
                <v-row class="py-10">
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon07.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Settlement in 90 seconds</div>
                    <div style="color: #707070">
                      Delivers Rapid Settlements: Merchants can enjoy swift transactions with settlements completed in 90 seconds, ensuring immediate access to their assets
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon08.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Anti-fraud and AML</div>
                    <div style="color: #707070">
                      Ensures Robust Security with Anti-Fraud and AML Measures: Safeguard user transactions and adhere to global financial regulations with integrated anti-fraud and Anti-Money Laundering systems
                    </div>
                  </v-col>
                  <v-col v-if="$vuetify.breakpoint.smAndUp" align-self="start" align="left" class="px-sm-10 mx-sm-10">
                  </v-col>
                </v-row>
                <v-row class="py-10">
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon09.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Community engagement through a proprietary payment method for merchants and individuals</div>
                    <div style="color: #707070">
                      B4G is committed to giving back and will work with all our partners, merchants, and consumers to encourage giving back in the community where all are deriving value. The proprietary payment method imbedded in Pay4.Global enables each merchant to create their own give back allowing them to engage their clients in innovative and important ways. B4G will not charge any transactional fees going to community charities or organizations helping improve society everywhere B4G expands
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleDarkComponent :custom-style="{ border: '0' }" fluid>
            <template v-slot:content>
              <v-img rel="preload" :src="require('@/assets/pay4global/banner2.jpg')" width="100%" height="60vh" position="center center">
                <div style="position: absolute; z-index: -1; top: 0; width: 100%; height: 100%; opacity: 50%; background-color: #007737"></div>
                <v-container class="fill-height" style="max-width: 1200px">
                  <v-row>
                    <v-col align-self="start" align="right">
                      <div class="d-flex" style="align-items: center">
                        <div class="mx-2" style="background-color: #ffffff88; backdrop-filter: blur(30px); width: 30px; height: 150px "> </div>
                        <div style="color: white; font-size: 200%; font-weight: 900; line-height: 100%" align="left">
                          Stands out the future
                        </div>
                      </div>
                    </v-col>
                    <v-col align-self="center" align="left" class="font-weight-medium" style="font-size: 70%; color: white">
                      As global markets become increasingly digitized, the importance of decentralized platforms will surge. Pay4.Global is well-poised to lead this transformation, tapping into burgeoning sectors like the tokenization of assets, seamless cross-border transactions, and holistic financial solutions for businesses.
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
            </template>
          </BannerSingleDarkComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid style="background-color: #f7f9fc">
            <template v-slot:content>
              <v-container style="font-size: 70%; max-width: 1600px" class="pa-15">
                <v-row class="py-10">
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon10.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Revenue Model with Proven Efficacy:</div>
                    <div style="color: #707070">
                      Our revenue-sharing partnership approach assures mutual growth and profitability. Our partners not only use our platform but also advocate for its utility.
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon11.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Strategic Business Flexibility:</div>
                    <div style="color: #707070">
                      We offer our partners adaptability, enabling them to tailor our robust technology to their geographic and business-specific needs, B4G catalyzes our partners ability to expand their territories
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon12.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Unrivaled Network Synergies:</div>
                    <div style="color: #707070">
                      Our platform fosters unparalleled synergies between partners. This network effect amplifies cross-revenue and cross-marketing, opening doors to new market segments.
                    </div>
                  </v-col>
                </v-row>
                <v-row class="py-10">
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon13.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">State-of-the-Art Tokenization:</div>
                    <div style="color: #707070">
                      We’re at the frontier of asset and transaction tokenization, heralding transparency and process optimization.
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon14.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Robust Risk Management:</div>
                    <div style="color: #707070">
                      Fraud and security risks are significantly curtailed thanks to our access to post-quantum computing security enabling leadership in advanced protective measures, protecting investment.
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                      <v-img :src="require('@/assets/pay4global/icons/Icon15.svg')" position="left" contain height="28px" max-heght="28px"></v-img>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Operational Excellence:</div>
                    <div style="color: #707070">
                      Our platform delivers on its promise of reduced costs while ensuring rapid transactions, giving businesses the agility, they crave.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleDarkComponent :custom-style="{ border: '0' }" fluid>
            <template v-slot:content>
              <v-img rel="preload" :src="require('@/assets/pay4global/banner3.jpg')" width="100%" height="60vh" position="center center">
                <div style="position: absolute; z-index: -1; top: 0; width: 100%; height: 100%; opacity: 50%; background-color: #003C81"></div>
                <v-container class="fill-height" style="max-width: 1200px">
                  <v-row>
                    <v-col align-self="start" align="right">
                      <div class="d-flex" style="align-items: center">
                        <div class="mx-2" style="background-color: #ffffff88; backdrop-filter: blur(30px); width: 30px; height: 150px "> </div>
                        <div style="color: white; font-size: 200%; font-weight: 900; line-height: 100%" align="left">
                          Strategic partner
                        </div>
                      </div>
                    </v-col>
                    <v-col align-self="center" align="left" class="font-weight-medium" style="font-size: 70%; color: white">
                      As industry leader in the Decentralized Economy Platform space, at Pay4.Global we aren’t just a service provider - we’re your business partner. Here’s how we redefine the B2B landscape:
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
            </template>
          </BannerSingleDarkComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleComponent fluid style="background-color: #f7f9fc">
            <template v-slot:content>
              <v-container style="font-size: 70%; max-width: 1600px" class="pa-15">
                <v-row class="py-10">
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Revenue Share Model:</div>
                    <div style="color: #707070">
                      We believe in mutual growth. By partnering with us, you tap into a revenue-sharing model that ensures shared success.
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Territorial & Business Model Flexibility:</div>
                    <div style="color: #707070">
                      Our partners leverage our robust technology tailored to their specific territories and unique business models.
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Synergistic Opportunities:</div>
                    <div style="color: #707070">
                      Collaborate with a vast network of our partners. Benefit from cross-revenue and cross-marketing opportunities, amplifying your business reach.
                    </div>
                  </v-col>
                </v-row>
                <v-row class="py-10">
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Enhanced Cost Efficiency:</div>
                    <div style="color: #707070">
                      With our platform, you achieve significant cost efficiencies, maximizing your ROI.
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Tokenization Expertise:</div>
                    <div style="color: #707070">
                      Stay ahead of the curve by utilizing our platform's capability to tokenize assets and transactions. This not only speeds up processes but also ensures greater transparency.
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10">
                    <div>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Mitigating Risks:</div>
                    <div style="color: #707070">
                      Our advanced anti-fraud mechanisms significantly diminish potential threats, safeguarding your business and consumer transactions.
                    </div>
                  </v-col>
                </v-row>
                <v-row class="py-10">
                  <v-col align-self="start" align="left" class="px-sm-10 mx-sm-10" cols="12" sm="4">
                    <div>
                    </div>
                    <div class="font-weight-bold" style="color: #3D81C3">Reduced Costs with Elevated Speed:</div>
                    <div style="color: #707070">
                      The decentralized nature of our platform lowers transaction costs. Meanwhile, our promise of settlements in 90 seconds ensures business agility.
                    </div>
                  </v-col>
                  <v-col align-self="start" align="left" class="px-sm-10">
                    <div style="font-size: 150%; font-weight: 600">
                      Empower your business by joining Pay4.Global network. Let's harness the future of decentralized economy solutions together.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </BannerSingleComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <BannerSingleDarkComponent style="background-color: #3D81C3" :custom-style="{ border: '0' }" fluid>
            <template v-slot:content>
                <v-container class="fill-height" style="max-width: 1500px; margin-top: 100px; margin-bottom: 100px;">
                  <v-row>
                    <v-col align-self="start" align="right" class="px-sm-15 mx-sm-15">
                      <div class="d-flex" style="align-items: center">
                        <div class="mx-2" style="background-color: #ffffff88; backdrop-filter: blur(30px); width: 30px; height: 150px "> </div>
                        <div style="color: white; font-size: 200%; font-weight: 600; line-height: 100%" align="left">
                          Get iD4Good+
                        </div>
                      </div>
                       <div class="my-5" style="font-weight: 400; font-size: 80%; color: white" align="left">
                        Download our iD4G+ App from Google Play, App Store and Ledger. Try our technology at https://beta.wallet4good.com/ and https://deptofgood-bloqs4good.web.app/
                      </div>
                    </v-col>
                    <v-col align-self="center" align="left" class="font-weight-medium" style="font-size: 70%; color: white">
                      <v-container fluid>
                        <v-row class="my-10" dense>
                          <v-col align-self="end" align="center" cols="12" sm="4" md="4" class="my-10">
                            <v-img :src="require('@/assets/pay4global/androide.png')"  aspect-ratio="1" height="400px" contain></v-img>
                            <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                              <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                            </v-btn>
                          </v-col>
                          <v-col align-self="end" align="center" cols="12" sm="4" md="4" class="my-10">
                            <v-img :src="require('@/assets/pay4global/ledger.png')" aspect-ratio="1" height="300px" contain></v-img>
                            <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                          </v-col>
                          <v-col align-self="end" align="center" cols="12" sm="4" md="4" class="my-10">
                            <v-img :src="require('@/assets/pay4global/iphone.png')" aspect-ratio="1" height="400px" contain></v-img>
                            <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                              <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
            </template>
          </BannerSingleDarkComponent>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_Pay4GlobalView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
export default {
  name: 'Pay4GlobalView',
  components: { BannerSingleDarkComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
