<template>
    <div class="DevicesComponent">
      <v-container fluid>
        <v-row>
        </v-row>
        <v-row style="min-height: 280px">
          <v-col class="mb-15 pb-10">
            <div class="Limiter">
              <div class="Apartados">
                <div v-for="index in 8" v-bind:key="'a' + index" class="Image">
                  <v-img :src="require('@/assets/apartados/' + index + '.png')" contain width="150px" height="150px" max-width="150px" min-height="150px" />
                </div>
                <div v-for="index in 8" v-bind:key="'b' +index" class="Image">
                  <v-img :src="require('@/assets/apartados/' + index + '.png')" contain width="150px" height="150px" max-width="150px" min-height="150px" />
                </div>
                <div v-for="index in 8" v-bind:key="'c' +index" class="Image">
                  <v-img :src="require('@/assets/apartados/' + index + '.png')" contain width="150px" height="150px" max-width="150px" min-height="150px" />
                </div>
                <div v-for="index in 8" v-bind:key="'d' +index" class="Image">
                  <v-img :src="require('@/assets/apartados/' + index + '.png')" contain width="150px" height="150px" max-width="150px" min-height="150px" />
                </div>
                <div v-for="index in 8" v-bind:key="'e' +index" class="Image">
                  <v-img :src="require('@/assets/apartados/' + index + '.png')" contain width="150px" height="150px" max-width="150px" min-height="150px" />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_DevicesComponent.scss'

export default {
  name: 'DevicesComponent'
}
</script>
