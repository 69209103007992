import meta from '@/meta.json'

export default {
  metaInfo () {
    return this.getMeta()
  },
  mounted () {
  },
  methods: {
    getMeta () {
      return meta[this.$route.path]
    }
  }
}
