<template>
    <div class="SimpleInfoBoxComponent">
        <v-container class="pa-10 my-5 fill-height" style="font-size: 150%; min-height: 400px">
          <v-row>
            <v-col>
              <div style="color: #559FC6" align="right">
                <slot name="Index">01</slot>
              </div>
              <div align="left" class="font-weight-bold mt-3" style="font-size: 120%; display: inline-flex; align-items: center">
                <slot name="Title"><v-icon class="mr-2">mdi-chat-question-outline</v-icon>FAQ</slot>
              </div>
              <diV align="left" class="font-weight-light mt-10" style="line-height: 120%">
                <slot name="Content">Get answers to frequently asked questions on Bloqs4Good products and services.</slot>
              </diV>
            </v-col>
          </v-row>
          <v-row class="px-2">
            <v-col align="left" class="font-weight-light" style="color: #559FC6">
              <slot name="Link"></slot>
            </v-col>
            <v-col align="right" class="font-weight-light">
              <slot name="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></slot>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_SimpleInfoBoxComponent.scss'
export default {
  name: 'SimpleInfoBoxComponent'
}
</script>
