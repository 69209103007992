<template>
    <div class="BannerTwoComponent">
      <v-container fluid :class="{ 'Container': true, 'pa-0': true, fluid: fluid }">
        <v-row>
          <v-col :class="{ FirstBox: true, fluid: fluid }" align-self="center" align="center" cols="12" sm="6" md="6">
            <slot name="FirstBox"></slot>
          </v-col>
          <v-col :class="{ SecondBox: true, fluid: fluid }" align-self="center" align="center" cols="12" sm="6" md="6">
            <slot name="SecondBox"></slot>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_BannerTwoComponent.scss'

export default {
  name: 'BannerTwoComponent',
  props: {
    fluid: Boolean
  }
}
</script>
