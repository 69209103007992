<template>
    <div class="VideoBannerComponent">
      <video ref="videoPlayer" class="video-js vjs-theme-bloqs4good" muted data-setup='{"fluid": true}'></video>
    </div>
</template>

<script>
import './_VideoBannerComponent.scss'
import videojs from 'video.js'

export default {
  name: 'VideoBannerComponent',
  props: {
    src: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      player: null,
      options: {
        autoplay: true,
        controls: true,
        loop: true,
        sources: [
        ]
      }
    }
  },
  mounted () {
    this.options.sources.push({ src: this.src, type: 'video/mp4' })
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady () {
      setTimeout(() => {
        // this.play()
      }, 100)
    })
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>
