<template>
    <div class="BannerFourComponent">
      <v-container fluid class="pa-0 fill-height">
        <v-row dense style="min-height: 360px">
          <v-col class="FirstBox" align-self="center" align="center" cols="12" sm="12" md="6">
            <slot name="FirstBox"></slot>
          </v-col>
          <v-col class="SecondBox" align-self="center" align="center" cols="12" sm="12" md="6">
            <slot name="SecondBox"></slot>
          </v-col>
          <v-col class="ThreeBox" align-self="center" align="center" cols="12" sm="12" md="6">
            <slot name="ThreeBox"></slot>
          </v-col>
          <v-col class="FourBox" align-self="center" align="center" cols="12" sm="12" md="6">
            <slot name="FourBox"></slot>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_BannerFourComponent.scss'

export default {
  name: 'BannerFourComponent'
}
</script>
