<template>
    <div class="AppFooterComponent">
      <v-footer dark color="#2D3843" min-height="680">
        <v-container>
          <v-row>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col md="12" lg="6">
              <div class="my-10">
                <v-img :src="require('@/assets/logoDark.png')" height="72px" max-width="264px" contain />
              </div>
              <div class="Description mx-5">
                At Bloqs4Good we are reinventing technology using humanistic approach. We believe everyone should own their DATA, sell it to those who want to use it and always expect security and privacy.
              </div>
              <div>
                <v-btn href=https://support.bloqs4good.com/hc/es class="text-capitalize px-10 my-10 mx-5" large light rounded>Support</v-btn>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <v-container class="">
                <v-row>
                  <v-col>
                    <v-btn text large block to="/contact" class="font-weight-bold text-capitalize text-h6 justify-start">FAQ's</v-btn>
                    <div class="mt-5">
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Get Started</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Plan</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Games</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Music</v-btn>
                    </div>
                  </v-col>
                  <v-col>
                    <v-btn text large block to="/contact"  class="font-weight-bold text-capitalize text-h6 justify-start">Company</v-btn>
                    <div class="mt-5">
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">About</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Empowerment</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Products</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Careers</v-btn>
                    </div>
                  </v-col>
                  <v-col>
                    <v-btn text large block to="/contact"  class="font-weight-bold text-capitalize text-h6 justify-start">Privacy Policy</v-btn>
                    <div class="mt-5">
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">ID4Good</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Wallet4Good</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Account</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Assets</v-btn>
                    </div>
                  </v-col>
                  <v-col>
                    <v-btn text large block to="/contact"  class="font-weight-bold text-capitalize text-h6 justify-start">Resources</v-btn>
                    <div class="mt-5">
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Help Center</v-btn>
                      <v-btn text large block to="/contact"  class="font-weight-light text-capitalize text-h6 justify-start">Contact</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div class="Separator"></div>
              <div class="mt-10">
                <v-btn href="https://instagram.com/bloqs4good" icon small class="mx-3"><v-img :src="require('@/assets/socials/instagram.svg')" contain aspect-ratio="1" max-width="24"></v-img></v-btn>
                <v-btn icon small class="mx-3"><v-img :src="require('@/assets/socials/twitter.svg')" contain aspect-ratio="1" max-width="24"></v-img></v-btn>
                <v-btn href="https://www.facebook.com/Bloqs4Good" icon small class="mx-3"><v-img :src="require('@/assets/socials/facebook.svg')" contain aspect-ratio="1" max-width="24"></v-img></v-btn>
                <v-btn icon small class="mx-3"><v-img :src="require('@/assets/socials/world.svg')" contain aspect-ratio="1" max-width="24"></v-img></v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </div>
</template>

<script>
import './_AppFooterComponent.scss'

export default {
  name: 'AppFooterComponent'
}
</script>
