<template>
    <div class="ContactView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top" style="box-shadow: 0 0 10px #00000044" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent>
              <template v-slot:content>
                <v-container style="min-height: 150px; max-width: 1200px">
                  <v-row>
                    <v-col align-self="center" align="left" style="color: #2F3D4D">
                      <div class="font-weight-regular px-5" style="font-size: clamp(1.2rem, 2vw, 2.2rem) !important; line-height: 100%; color: #2F3D4D">
                        We are excited our business model which is unique and will support our partners to bring their business models into the sharing economy and digital transformation. We welcome you to send a message below if you would like to speak.
                      </div>
                      <div style="position: absolute; right: 0">
                        <div style="position: relative; bottom: -50px; right: 50px">
                          <v-img rel="preload" :src="require('@/assets/logo.png')" max-width="200px" contain />
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container style="min-height: 150px; max-width: 1200px">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="font-weight-regular px-5" style="font-size: clamp(2rem, 4vw, 5rem) !important; line-height: 100%;">
                        <strong>Looking</strong> for a Collaborative <strong>Project?</strong> Let’s <strong>CHAT</strong>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="center" align="center">
            <BannerSingleComponent :fluid="true">
              <template v-slot:content>
                <v-container style="min-height: 150px; max-width: 1200px;" class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="my-10">
                        CONTACT
                      </div>
                      <div style="font-size:clamp(1rem, 2vw, 2rem)">
                        Please send us a message if you would like to communicate with us, we will be happy to get back to you asap.
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div style="min-height: 150px; max-width: 1200px; width: 50vw" class="my-15">
                        <script type="application/javascript">
                          window.hbspt.forms.create({
                            region: "na1",
                            portalId: "5179500",
                            formId: "ec91883e-6963-4cfd-85af-4d72fcf52500"
                          });
                        </script>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
export default {
  name: 'ContactView',
  components: { BannerSingleDarkComponent, BannerSingleComponent, NotifyComponent },
  mounted () {
    setTimeout(() => {
      this.setStyles()
    }, 1000)
  },
  methods: {
    setStyles () {
      const frameElement = document.getElementsByClassName('hbspt-form')[0].children
      const doc = frameElement[0].contentDocument
      doc.head.innerHTML = doc.head.innerHTML + '<style> .submitted-message { text-align: center !important; } .hs-button { color: black !important; background: white !important; border-radius: 30px; border-width: 2px; } </style>'
    }
  }
}
</script>
