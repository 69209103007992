<template>
    <div class="RotorComponent" ref="rotor">
      <div class="ring-bottom">
        <v-img :src="require('@/assets/rotor/ring-bottom.svg')" width="250px" contain></v-img>
      </div>
      <div class="ring">
        <v-img :src="require('@/assets/rotor/ring.svg')" width="350px" contain></v-img>
      </div>
      <div class="ring-top">
        <v-img :src="require('@/assets/rotor/ring-top.svg')" width="420px" contain></v-img>
      </div>
      <div class="tech" ref="tech">
        <div class="icon">
          <v-img :src="require('@/assets/rotor/id.svg')" height="64px" width="64px" aspect-ratio="1" contain />
        </div>
        <div class="icon">
          <v-img :src="require('@/assets/rotor/wallet.svg')" height="64px" width="64px" aspect-ratio="1" contain />
        </div>
        <div class="icon">
          <v-img :src="require('@/assets/rotor/box.svg')" height="64px" width="64px" aspect-ratio="1" contain />
        </div>
        <div class="icon">
          <v-img :src="require('@/assets/rotor/node.svg')" height="64px" width="64px" aspect-ratio="1" contain />
        </div>
        <div class="icon">
          <v-img :src="require('@/assets/rotor/pay.svg')" height="64px" width="64px" aspect-ratio="1" contain />
        </div>
      </div>
    </div>
</template>

<script>
import './_RotorComponent.scss'
export default {
  name: 'RotorComponent',
  props: {
    position: Number
  },
  data () {
    return {
      deg: -10
    }
  },
  mounted () {
    const position = this.position ? this.position : 0
    this.deg = -10 + 72 * position
    this.$refs.rotor.style.setProperty('--rotor-angle', this.deg + 'deg')
  },
  watch: {
    position: function (value) {
      const position = value || 0
      this.deg = -10 + 72 * position
      this.$refs.rotor.style.setProperty('--rotor-angle', this.deg + 'deg')
    }
  },
  methods: {
    move () {
      this.deg += 72
      this.$refs.rotor.style.setProperty('--rotor-angle', this.deg + 'deg')
    }
  }
}
</script>
