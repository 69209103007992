<template>
    <div class="GetStartedView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">Join us on a journey into the new economy, go to apple store or google play and download ID4Good your private gateway completely secure</span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/getstarted/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/getstarted/getstarted.svg')" max-width="369px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          All our products and services are built around a humanistic approach, where technology serves people and entrepreneurs alike, helping them to empower and pull their creativity to the next level in the new digital world. Either if you are new or experienced in decentralized technology, blockchain, cryptos and NFT. Get your iD4Good App, and start now.
                        </div>
                        <div style="max-width: 350px">
                          <v-container>
                            <v-row dense>
                              <v-col align-self="center">
                                <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                                <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                                </v-btn>
                              </v-col>
                              <v-col align-self="center">
                                <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                                <v-img :src="require('@/assets/apple-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          It’s easy to get started with us, get iD4Good and visit Wallet4Good.com or DeptOfGood.com to know more. You’ll soon notice how our tools are designed to give you complete freedom of choice. We are not willing to give up defending your privacy (or your client’s privacy), so our ecosystem is built around that premise. But there is one more thing, we actively give part of our revenues to charity, so anything you do with us is also committed to that mission.
                        </div>
                    </v-col>
                  </v-row>
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <div style="color: #6EACE8; font-size: 1rem">Android</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="10000"
                          :duration="2"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Downloads</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <div style="color: #6EACE8; font-size: 1rem">Ledger</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="1000"
                          :duration="2.5"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Downloads</div>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <div style="color: #6EACE8; font-size: 1rem">iOS</div>
                      <div>
                        <number
                          class="transition"
                          :from="0"
                          :to="10000"
                          :duration="3"
                          easing="Power4.easeOut"
                        />+
                      </div>
                      <div class="overline text-capitalize" style="line-height: 80%">Downloads</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good" x-large>Get id4Good</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/getstarted/01.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/getstarted/02.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height my-15">
                  <v-row>
                    <v-col align-self="center" align="center" style="font-size: 200%; line-height: 100%">
                        <div>True <span class="font-weight-bold">decentralization</span></div>
                      <div>means freedom</div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/getstarted/banner2.png')" width="100%" height="100%" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">One iD <strong>for all</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          So simple! Get iD4Good for your preferred device(s) (available for Android, iOS and Ledger) and enjoy what our network and ecosystem offer you now, and what is coming for everybody and from new developers, partners and companies in the short term. You can be a part of this by building or empowering your business with us! or you can get a Node4Good as well, and be an active participant in the network!
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <div style="font-size: 150%">
                      Get iD4Good, <strong>start now</strong>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div style="font-size: 1.4rem; line-height: 150%">
                        Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/iD4Good.svg')" aspect-ratio="1" contain width="48px" />iD4Good</template>
              <template slot="Content">One key to unlock all with your iD4Good, all starts here!</template>
              <template slot="Link">Get the app</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/Pay4Good.svg')" aspect-ratio="1" contain width="48px" />Pay4Good</template>
              <template slot="Content">Pay everywhere, anytime, and contactless using Pay4Good.</template>
              <template slot="Link">Contact us</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/Wallet4Good.svg')" aspect-ratio="1" contain width="48px" />Wallet4Good</template>
              <template slot="Content">Freely manage your digital assets and cryptos with Wallet4Good.</template>
              <template slot="Link">Go Wallet4Good.com</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/DeptOfGood.svg')" aspect-ratio="1" contain width="48px" />Dept. of Good</template>
              <template slot="Content">Create, showcase, share, explore, sell and buy with Dept. of Good.</template>
              <template slot="Link">Go DeptOfGood.com</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">05</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/Box4Good.svg')" aspect-ratio="1" contain width="48px" />Box4Good</template>
              <template slot="Content">Boost, store, earn, accept payments, participate with Box4Good.</template>
              <template slot="Link">Request your box</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">06</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/4GoodNetwork.svg')" aspect-ratio="1" contain width="48px" />Network4Good</template>
              <template slot="Content">Participate and earn in the 4GoodNetwork with Node4Good.</template>
              <template slot="Link">Contact us</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">07</template>
              <template slot="Title">
                <div>
                <div class="mb-1"><v-img class="mr-2 d-inline-flex" :src="require('@/assets/icons/iconboxes/Apta4Goos.svg')" aspect-ratio="1" contain width="48px" style="vertical-align: top" />Apta4Good</div>
                <div><v-img class="mr-2 d-inline-flex" :src="require('@/assets/icons/iconboxes/Token4Good.svg')" aspect-ratio="1" contain width="48px" style="vertical-align: top" />Token4Good</div>
                </div>
              </template>
              <template slot="Content">Buy, sell, pay, earn using our Token4Good and Apta4Good.</template>
              <template slot="Link">Know more</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">07</template>
              <template slot="Title"><v-img class="mr-2" :src="require('@/assets/icons/iconboxes/comfortable.svg')" aspect-ratio="1" contain width="48px" />News</template>
              <template slot="Content">And there is a lot much more to come! Stay tuned.</template>
              <template slot="Link">Suscribe for news</template>
              <template slot="Button"><v-btn color="#569FC7" dark fab small tile><v-icon>mdi-arrow-right-thin</v-icon></v-btn></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_GetStartedView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'

export default {
  name: 'GetStartedView',
  components: { BannerTwoComponent, SimpleInfoBoxComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
