<template>
    <div class="ProductsBoxComponent">
      <v-container fluid class="Container pa-0">
        <v-row dense>
          <v-col style="background: transparent linear-gradient(328deg, #4C6771 0%, #263439 100%) 0% 0% no-repeat padding-box;" class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12" @mouseenter="displayoff = 'wallet4good'" @mouseleave="display = null">
            <div class="ProductName mt-10" style="color: white">
              <span class="ProductLight"></span><span class="ProductBold">More innovation</span>
              <div style="color: white" class="Subtitle">Metaverse digital marketplace will run on our fully decentralized network</div>
              <div class="Link">
                <a class="mx-5" href="/deptofgood">Know about Dept. of Good ></a>
                <a class="mx-5" href="/network4good">Know about our network ></a>
              </div>
            </div>
            <div class="mt-13">
              <v-img contain rel="preload" :src="require('@/assets/devices/deptofgood.png')" class="ImageContainer" v-if="display !== 'wallet4good'" />
            </div>
          </v-col>
          <v-col v-if="false" style="background: transparent linear-gradient(180deg, #FCBEBE 0%, #FFE0E0 100%) 0% 0% no-repeat padding-box;" class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12"  @mouseenter="displayoff = '4goodnetwork'" @mouseleave="display = null">
            <div class="ProductName mt-10">
              <span class="ProductBold">4Good</span><span class="ProductLight">Network</span>
              <div class="Subtitle">Democratizing decentralized infrastructure participation</div>
              <div class="Link">
                <a href="/network4good">Learn More ></a>
              </div>
            </div>
            <div class="my-13">
              <v-img position="-250% 20%" rel="preload" :src="require('@/assets/devices/4goodnetwork.png')" class="ImageContainer size-disabled" v-if="display !== '4goodnetwork'" />
            </div>
          </v-col>
          <v-col v-if="false" class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12" @mouseenter="displayoff = 'deptofgood'" @mouseleave="display = null">
            <div class="ProductName mt-10">
              <span class="ProductLight">Dept. Of </span><span class="ProductBold">Good</span>
              <div class="Subtitle">Your friendly Web3 e-commerce platform</div>
              <div class="Link">
                <a href="/deptofgood">Learn More ></a>
              </div>
            </div>
            <div class="my-4">
              <v-img rel="preload" :src="require('@/assets/devices/deptofgood.png')" class="ImageContainer" contain v-if="display !== 'deptofgood'" />
            </div>
          </v-col>
          <v-col class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12" @mouseenter="displayoff = 'box4good'" @mouseleave="display = null">
            <v-img rel="preload" :src="require('@/assets/devices/scanning-box.jpg')" width="100%" height="100%" style="position: relative;">
            <div style="z-index: -1; background: transparent linear-gradient(180deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box; height: 200px; width: 100%; position: absolute"> </div>
            <div class="ProductName mt-10" style="color: white">
              <span class="ProductLight">Box</span><span class="ProductBold">4Good</span>
              <div style="color: white" class="Subtitle">Innovative hardware for a truly decentralized network</div>
              <div class="Link">
                <a href="/box4good">Learn More ></a>
              </div>
            </div>
            </v-img>
          </v-col>
          <!-- v-col class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12" @mouseenter="displayoff = 'id4good'" @mouseleave="display = null">
            <div class="ProductName mt-10">
              <span class="ProductLight">iD</span><span class="ProductBold">4Good</span>
              <div class="Subtitle">Self-Custody digital identity</div>
              <div class="Link">
                <a href="/id4good">Learn More ></a>
              </div>
            </div>
            <div class="my-4">
              <v-img rel="preload" :src="require('@/assets/devices/id4good.png')" class="ImageContainer" contain v-if="display !== 'id4good'" />
            </div>
            <div class="Icon" align="right">
              <v-img :src="require('@/assets/icons/NT ID4Good.svg')" contain max-height="100px" max-width="100px" />
            </div>
          </v-col>
          <v-col class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12" @mouseenter="displayoff = 'pay4good'" @mouseleave="display = null">
            <div class="ProductName mt-10">
              <span class="ProductLight">Pay</span><span class="ProductBold">4Good</span>
              <div class="Subtitle">Fully integrated OmniPayment solution</div>
              <div class="Link">
                <a href="/pay4good">Learn More ></a>
              </div>
            </div>
            <div class="my-4">
              <v-img rel="preload" :src="require('@/assets/devices/pay4good.png')" class="ImageContainer" contain v-if="display !== 'pay4good'" />
            </div>
            <div class="Icon" align="right">
              <v-img :src="require('@/assets/icons/NT Pay4Good.svg')" contain max-height="100px" max-width="100px" />
            </div>
          </v-col>
          <v-col class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12" @mouseenter="displayoff = 'wallet4good'" @mouseleave="display = null">
            <div class="ProductName mt-10">
              <span class="ProductLight">Partners</span>
              <div class="Subtitle">We are proud to announce a Partnership with Sambax and Orcaex in launching mutiple solutions in LATAM - Launching Sep 2022</div>
              <div class="Link">
                <a href="/partners">Learn More ></a>
              </div>
            </div>
            <div class="my-4">
              <div class="ImageContainer" style="display: inline-grid">
                <v-img rel="preload" :src="require('@/assets/devices/partners.png')" class="ImageContainer" contain v-if="display !== 'wallet4good'" />
              </div>
            </div>
          </v-col>
          <v-col class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12" @mouseenter="displayoff = 'pay4good'" @mouseleave="display = null">
            <div class="ProductName mt-10">
              <span class="ProductLight">Community </span><span class="ProductBold">Empowerment</span><span class="ProductLight"></span>
              <div class="Subtitle">If you are a community organization (people, entrepreneurs,
                charity, businesses, etc) and would like to participate,
                join us in empowering your community and SME</div>
              <div class="Link">
                <a href="/community">Learn More ></a>
              </div>
            </div>
            <div class="my-4">
              <v-img rel="preload" :src="require('@/assets/devices/empowerment.png')" class="ImageContainer" contain v-if="display !== 'wallet4good'" />
            </div>
          </v-col>
          <v-col class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12" @mouseenter="displayoff = 'wallet4good'" @mouseleave="display = null">
            <div class="ProductName mt-10">
              <span class="ProductLight">Node</span><span class="ProductBold">4Good</span>
              <div class="Subtitle">Building a decentralized network managed, owned and benefitting individuals</div>
              <div class="Link">
                <a href="/node4good">Learn More ></a>
              </div>
            </div>
            <div class="my-4">
              <v-img rel="preload" :src="require('@/assets/devices/node4good.png')" class="ImageContainer" contain v-if="display !== 'wallet4good'" />
            </div>
          </v-col>
          <v-col class="BoxColorPrimary" align-self="center" align="center" md="6" sm="12" @mouseenter="displayoff = 'wallet4good'" @mouseleave="display = null">
            <div class="ProductName mt-10">
              <span class="ProductLight">Sandbox</span><span class="ProductBold">4Good</span>
              <div class="Subtitle">Opening our technology for a infinite universe of new apps, solutions and business</div>
              <div class="Link">
                <a href="/sandbox">Learn More ></a>
              </div>
            </div>
            <div class="my-4">
              <v-img rel="preload" :src="require('@/assets/devices/sandbox.png')" class="ImageContainer" contain v-if="display !== 'wallet4good'" />
            </div>
          </v-col -->

        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_ProductsBoxComponent.scss'

export default {
  name: 'ProductsBoxComponent',
  data () {
    return {
      display: null,
      displayoff: null
    }
  }
}
</script>
