<template>
    <div class="Box4GoodView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">Box4Good is an innovative way for individuals and businesses to participate in our ecosystem</span>
                <span style="font-size: 0.9rem" class="mx-5"><a href="/id4good">Id4Good</a></span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/box4good/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/box4good/box4good.svg')" max-width="300px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Box4Good is multifunctional and versatile. Whether home, office or retail use, it can be integrated from Payment Processing, to storage of assets, securing files and encrypting them, to eventually receiving services. Box4Good can be embedded with transmission boosting capabilities to help connectivity.
                          Are you a creator? Mint 100x faster with your Box4Good. Would you like to be part of the a decentralized blockchain network and be compensated for being a partner? Join us and get your own Node, benefit from all the services and be compensated for being a valued member of our network.                        </div>
                        <div style="max-width: 350px">
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          The 4GoodNetwork will deliver true decentralization with exceptional energy efficiency and unparalleled cost effectiveness.
                          We developed our hardware to create a new optimization paradigm in a revolutionary decentralized Blockchain network. We will deliver the highest level of speed, efficiency and almost zero environmental impact. </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-container>
                        <v-row>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/enviroment.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Environment Care
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Maximum power efficiency For more affordable and Eco-friendly processing.
                                Biodegradable device housing.
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/flexible.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Customizable
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Different colors and Customization. Can be Branded to meet client’s Specifications.
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/ubiquitous.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Node capabilities
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Safely can act as node In the 4GoodNetwork Turning into a new Revenue source for owners
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/comfortable.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Multifunctional
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Box4Good can handle Diverse roles, from Transaction booster to Contactless Point of Sales
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Contact Us</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/box4good/12.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/box4good/20.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center" style="font-size: 120%; line-height: 100%">
                        <div><span class="font-weight-bold">Innovative</span></div>
                      <div>Multifunctional Hardware</div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/box4good/boxes.png')" width="100%" position="top center" max-width="2048px" contain>
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container class="fill-height my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Project on your <strong>Home Screens</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10" align="left">
                          Our Box4Good is compact and powerful. B4G can manage all your personal important files, and your NFT collections, thanks to a robust built in storage with strong post quantum ready encryption algorithms. B4G is easily linked to your home screens, using HDMI ports which also supports 4K resolution. Project all your NFTs photography or future entertainment directly to your screens and enjoy the future. Both models (with screen and without) are compatible and are an asset in your Wallet4Good self custody wallet. </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-icon class="mr-2">mdi-transit-connection-horizontal</v-icon>Connected</template>
              <template slot="Content">
                <li>Wi-Fi IEEE 802.11ac</li>
                <li>Bluetooth 5.0</li>
                <li>NFC/RFID</li>
                <li>Gigabit ethernet</li>
                <li>Micro HDMI (up to 4k60fps)</li></template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-icon class="mr-2">mdi-cart-outline</v-icon>As PoS</template>
              <template slot="Content"><li>Let your clients pay contactless with Pay4Good using on screen QR or NFC validation with iD4Good.</li></template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-icon class="mr-2">mdi-dots-square</v-icon>More Features</template>
              <template slot="Content">
                <li>Barometric pressure sensor</li>
                <li>Temperature sensor</li>
                <li>Altimeter</li>
                <li>Can be cloned up to 2,500 times</li>
                <li>Screen cast</li></template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-icon class="mr-2">mdi-currency-usd</v-icon>Minting</template>
              <template slot="Content"><li>Mint faster, with only 15W of maximum power consumption.</li>
                <li>Low fees for transaction and compensation in Apta4Good for owners.</li></template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Iconic and <strong>democratic</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Box4Good hardware is incredibly versatile, and has full capabilities to deploy our patented Cloudless Network the way it is needed while serving its intended role, breaking the current paradigm of servers into a true decentralized network environment. Box4Good roles are diverse (for example, Pay4Good PoS, Node4Good computing node, Wallet4Good vault, Dept. of Good minting booster), and we are sure that it will have many more  roles in the short term thanks to the creation of new business models and dApps driven by third parties using our Sandbox developer toolkit. We will be happy to welcome your business, service or dApp in our Box4Good hardware.</div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" href="/contact" x-large>Order a Box4Good</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/box4good/boxes2.png')" width="100%" height="80vh" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Easy and <strong>Plug&Play</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Get your Box4Good, turn it on and start all just scanning, with your iD4Good App, the QR code displayed on the built-in display (models with display) or any HDMI connected external display or TV (models with or without display). The box instantly will be assigned to your property, giving you access to all your assets and allowing you to perform any actions related to them.</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <div style="font-size: 150%">
                      Get iD4Good, <strong>start now</strong>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div style="font-size: 1.4rem; line-height: 150%">
                        Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Box4GoodView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'

export default {
  name: 'Box4GoodView',
  components: { BannerTwoComponent, SimpleInfoBoxComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
