<template>
    <div class="CompareComponent">
        <v-container fluid>
          <v-row style="min-height: 600px">
            <v-col align-self="center" align="center">
              <slot name="left">
                <v-img :src="require('@/assets/logo.png')" height="180px" max-width="450px" width="70%" contain />
                <div class="font-weight-light text-h5 font-italic my-10" style="max-width: 600px">
                  O4G is bridging the journey from analog to digital economy with intelligent solutions for existing, new, and developing markets.
                </div>
                <v-btn dark x-large class="px-10 py-8 Button" color="#2D3843">Sign Up Early</v-btn>
              </slot>
            </v-col>
            <v-col align-self="center" align="center">
              <img-comparison-slider class="CompareImage">
                <v-img height="40vw" :width="$vuetify.breakpoint.mdAndDown ? '80vw': '40vw'" max-height="550px" slot="first" :src="first ? first : 'https://img-comparison-slider.sneas.io/images/bright-leaves.webp'" />
                <v-img height="40vw" :width="$vuetify.breakpoint.mdAndDown ? '80vw': '40vw'" max-height="550px" slot="second" :src="second ? second : 'https://img-comparison-slider.sneas.io/images/bright-leaves-blurred.webp'" />
              </img-comparison-slider>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_CompareComponent.scss'

export default {
  name: 'CompareComponent',
  props: {
    first: String,
    second: String
  }
}
</script>
