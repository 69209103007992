<template>
    <div class="DeptOfGoodView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">Dept of Good is a revolutionary platform for Network solution built on blockchain enabling Physical, Digital and hybrid assets, ready for Web 3.0</span>
                <span style="font-size: 0.9rem" class="mx-5"><a>iD4Good</a></span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/deptofgood/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/deptofgood/deptofgood.svg')" max-width="250px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Our Dept. of Good platform is an amazing showcase of what our technology can do to create an entirely new user experience. We envisioned and created Dept. of Good to become the most democratic e-commerce store ever built E-Commerce is evolving into a hybrid of brick and mortar and online . We have built Dept of Good to help small business, individuals to benefit from the peer2peer economy.  The vision of a market network where people can exchange every asset efficiently and in security.Our all encompassing meeting place has been built without compromising user friendliness, efficiency and costs. Our motivation is to bring people and SMEs closer to the new fully digital economy, providing them with the right tools to achieve success.</div>
                        <div style="max-width: 350px">
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          Blockchain technology is an important component in the concept of decentralization. Decentralization is our 4Goodnetwork ecosystem and Dept of Good. benefits of all the products of our ecosystem bringing to life a disruptive Market Network with every exciting model including e-commerce, NFTs, linking Brick and Mortar retail to NFTs, multimedia ready. B4Gs Blockchain is fast, green and cost-efficient. Mint, trade, sell have fun. Secure log-in with ID4Good, linked to the Wallet4Good to store and safe-keep your assets. Payment Platform can accommodate over 250 payments. The Box4Good adds services and able to store all your assets with a potential revenue streams for the holder.
                        </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-container>
                        <v-row>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/iD4Good.svg')" aspect-ratio="1" contain width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Tap n’ Go
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                iD4good login, fast and hyper secure
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/Wallet4Good.svg')" aspect-ratio="1" contain width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Cost friendly
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Full integrated with Wallet4good content
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/Pay4Good.svg')" aspect-ratio="1" contain width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Ubiquitous
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Pay4good ensures the fastest, easiest and most versatile payment
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/Box4Good.svg')" aspect-ratio="1" contain width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Boostable
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Supports box4good integration to boost 100x All transactions
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/4GoodNetwork.svg')" aspect-ratio="1" contain width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Expandable
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Ready to support 4goodnetwork future features
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" x-large>Visit Dept. of Good</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/deptofgood/01.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/deptofgood/02.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height my-15">
                  <v-row>
                    <v-col align-self="center" align="center" style="font-size: 120%; line-height: 100%">
                        <div>Pioneering <span class="font-weight-bold">the Good</span></div>
                      <div>Economy</div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/deptofgood/banner2.png')" width="100%" height="100%" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="min-height: 60vh" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Full of <strong>features</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10" align="left">
                          Dept. of Good is the first e-commerce that brings together a true sell platform for physical goods, hybrid (a new category that mix physical and NFT) and NFT, all with the lowest transaction fee and  with no hidden costs. Dept. of Good is a complete virtual shopping, that features customized environments for individuals and SMEs with all the 4GoodNetwork features included.</div>
                      </div>
                      <v-row>
                        <v-col align-self="center" align="center">
                          <v-container>
                            <v-row>
                              <v-col>
                                <div style="width: 200px">
                                  <div>
                                    <v-img :src="require('@/assets/icons/iconboxes/stats.svg')" aspect-ratio="1" width="48px"></v-img>
                                  </div>
                                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                    Tap n’ Go
                                  </div>
                                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                    Complete stats available for users
                                  </div>
                                </div>
                              </v-col>
                              <v-col>
                                <div style="width: 200px">
                                  <div>
                                    <v-img :src="require('@/assets/icons/iconboxes/unbreakable.svg')" aspect-ratio="1" width="48px"></v-img>
                                  </div>
                                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                    Unbreakeable
                                  </div>
                                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                    All assets encrypted and secured
                                  </div>
                                </div>
                              </v-col>
                              <v-col>
                                <div style="width: 200px">
                                  <div>
                                    <v-img :src="require('@/assets/icons/iconboxes/nft.svg')" aspect-ratio="1" width="48px"></v-img>
                                  </div>
                                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                    NFT Trade
                                  </div>
                                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                    Mint, Buy, sell, loan, earn, lease, auction, permanent Royalties, etc.
                                  </div>
                                </div>
                              </v-col>
                              <v-col>
                                <div style="width: 200px">
                                  <div>
                                    <v-img :src="require('@/assets/icons/iconboxes/communicate.svg')" aspect-ratio="1" width="48px"></v-img>
                                  </div>
                                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                    Communicate
                                  </div>
                                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                    Conferencing tools included
                                  </div>
                                </div>
                              </v-col>
                              <v-col>
                                <div style="width: 200px">
                                  <div>
                                    <v-img :src="require('@/assets/icons/iconboxes/versatile.svg')" aspect-ratio="1" width="48px"></v-img>
                                  </div>
                                  <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                    Versatile
                                  </div>
                                  <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                    Supports multiple formats including multimedia and 3d
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                      <div style="max-width: 350px">
                        <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" x-large>Bring me there</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">As valuable <strong>as safe</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Behind Dept. of good an amazing technology foundation keeps all your assets and data safe and ready to be shared the way you want. Dept. of Good allows you to create, sell and buy any type of content and in multiple ways, including art, multimedia or hybrid assets composed of physical and digital components. Whether you are either an individual and are just starting in this new world of NFT and digital assets or you are a company that is looking for new distribution channels, you’ll find in the Dept. of Good a place where the limit is just your imagination. Get your copy of iD4Good and try Dept. of Good, you’ll love it.</div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#FFFFFF" class="px-10" style="border-width: medium" height="64px" x-large>Bring me there</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/deptofgood/banner3.png')" width="100%" height="80vh" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-icon class="mr-2">mdi-access-point-network</v-icon>Purpose built network</template>
              <template slot="Content">Using technologies embedded in our proprietary 4GoodNetwork, Dept. of Good achieves unparalleled speed and efficiency across all transactions.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-icon class="mr-2">mdi-thermometer-low</v-icon>Low impact</template>
              <template slot="Content">Thanks to highly efficient algorithms, low-power processing and dedicated hardware we have the lowest power consumption per transaction.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-icon class="mr-2">mdi-clock-fast</v-icon>Amazingly fast</template>
              <template slot="Content">We offer true decentralization and the fastest processing on the market, being able to process transactions more than twice as fast as the next competitor on the list.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-icon class="mr-2">mdi-thermometer-low</v-icon>Low fees</template>
              <template slot="Content">Our efficiency thanks to 4GoodNetwork provides users and companies the lowest fees on market without hidden costs. Benefits are multiplied with service fees as low as 1.5-2.5%</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">05</template>
              <template slot="Title"><v-icon class="mr-2">mdi-charity</v-icon>4Good</template>
              <template slot="Content">All our paid transactions donate a fixed percentage to charity, and users and businesses in our network can also make voluntary contributions to charity and community.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">06</template>
              <template slot="Title"><v-icon class="mr-2">mdi-shield-check-outline</v-icon>Box4Good boost</template>
              <template slot="Content">Box4Good is plug and play hardware able to boost minting up to 100x faster, and also allows for improved storage. It can also be used in showcase mode.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">07</template>
              <template slot="Title"><v-icon class="mr-2">mdi-virtual-reality</v-icon>Virtual Room</template>
              <template slot="Content">Showcase your assets and collections in standard and virtual environments. Dept. of Good is ready for the Metaverse and supports 3D, AR, VR too.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">07</template>
              <template slot="Title"><v-icon class="mr-2">mdi-contactless-payment-circle-outline</v-icon>Pay4Good</template>
              <template slot="Content">Dept. of Good natively integrates Pay4Good, which means it allows transactions with Cryptos and Tokens, Digital Wallets and traditional credit and debit with FIAT currency.</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Get iD4Good, <strong>start now</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_DeptOfGoodView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'

export default {
  name: 'DeptOfGoodView',
  components: { BannerTwoComponent, SimpleInfoBoxComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
