<template>
    <div class="AppBarComponent">
      <div style="height: 96px" v-if="$store.state.scrollY > 0 && $route.name === 'home'"></div>
        <v-app-bar dark color="#323d4c" :class="{ appBar: true, 'px-sm-2': true, 'px-lg-10': true, changeBackground: $store.state.scrollY > 0 && $route.name === 'home' }" height="96" :fixed="$store.state.scrollY > 0 && $route.name === 'home'">
          <v-img @click="$router.push({ name: 'home' })" :src="require('@/assets/logoDark.png')" height="96" max-width="220px" contain />
          <v-spacer />
          <div v-if="$vuetify.breakpoint.width > 960">
          <v-btn active-class="ActiveButton" small text class="Button" to="/aboutus" v-if="$vuetify.breakpoint.width > 1000">About Us</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/empowerment" v-if="$vuetify.breakpoint.width > 1200">Empowerment</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/getstarted" v-if="$vuetify.breakpoint.width > 1300">Get Started</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/id4good">iD4Good</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/pay4global">Pay4Global</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/pay4good">Pay4Good</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/wallet4good">Wallet4Good</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/network4good">4GoodNetwork</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/deptofgood">Dept. Of Good</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/box4good">Box4Good</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/partners" v-if="$vuetify.breakpoint.width > 1400">Partners</v-btn>
          <v-btn active-class="ActiveButton" small text class="Button" to="/community">Community</v-btn>
          </div>
          <v-spacer />
          <div style="max-width: 220px" align="right">
          <v-btn icon class="mx-2 hidden-sm-and-down" to="/contact" v-if="false"><v-icon>mdi-cube-outline</v-icon></v-btn>
          <v-btn icon class="mx-2 hidden-md-and-up" @click="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-btn>
          </div>
        </v-app-bar>
      <v-navigation-drawer dark right app v-model="drawer" color="#323d4c" v-if="!$vuetify.breakpoint.mdAndUp">
        <div align="center">
          <v-img :src="require('@/assets/logoDark.png')" height="48" max-width="140px" contain />
        </div>
        <v-list
          dense
          nav
        >
          <v-list-item
            v-for="item in items"
            :key="item.name"
            link
            :to="item.to"
          >
            <v-list-item-content>
              <v-list-item-title align="right">{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
</template>

<script>
import './_AppBarComponent.scss'

export default {
  name: 'AppBarComponent',
  data () {
    return {
      drawer: false,
      items: [
        { to: '/partners', name: 'Partners' },
        { to: '/getstarted', name: 'Get Started' },
        { to: '/empowerment', name: 'Empowerment' },
        { to: '/aboutus', name: 'About Us' },
        { to: '/network4good', name: '4GoodNetwork' },
        { to: '/box4good', name: 'Box4Good' },
        { to: '/id4good', name: 'iD4Good' },
        { to: '/wallet4good', name: 'Wallet4Good' },
        { to: '/pay4global', name: 'Pay4Global' },
        { to: '/pay4good', name: 'Pay4Good' },
        { to: '/deptofgood', name: 'Dept. Of Good' }
      ]
    }
  }
}
</script>
