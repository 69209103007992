<template>
    <div class="PartnersComponent">
      <v-container fluid>
        <v-row>
          <v-col align-self="center" align="center">
              <div class="Banner my-15">
                <strong>Trusted</strong> by our <strong>Partners</strong>
              </div>
          </v-col>
        </v-row>
        <v-row style="min-height: 280px">
          <v-col class="mb-15 pb-15">
            <div class="Limiter">
              <div class="Partners">
                <div v-for="index in 4" v-bind:key="'a' + index" class="Image">
                  <v-img :src="require('@/assets/partners/' + index + '.jpg')" contain width="150px" height="150px" max-width="150px" min-height="150px" />
                </div>
                <div v-for="index in 4" v-bind:key="'b' +index" class="Image">
                  <v-img :src="require('@/assets/partners/' + index + '.jpg')" contain width="150px" height="150px" max-width="150px" min-height="150px" />
                </div>
                <div v-for="index in 4" v-bind:key="'c' +index" class="Image">
                  <v-img :src="require('@/assets/partners/' + index + '.jpg')" contain width="150px" height="150px" max-width="150px" min-height="150px" />
                </div>
                <div v-for="index in 4" v-bind:key="'c' +index" class="Image">
                  <v-img :src="require('@/assets/partners/' + index + '.jpg')" contain width="150px" height="150px" max-width="150px" min-height="150px" />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_PartnersComponent.scss'

export default {
  name: 'PartnersComponent'
}
</script>
