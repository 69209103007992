<template>
    <div class="Wallet4GoodView">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <NotifyComponent class="slide-in-top">
              <template slot="content">
                <span style="font-size: 0.9rem">Welcome to W4G; innovation, for WEB 3.0. Self-Custody wallet which seamlessly toggles between FIAT and DEFI</span>
                <span style="font-size: 0.9rem" class="mx-5"><a href="/id4good">Id4Good</a></span>
              </template>
            </NotifyComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/wallet4good/banner.png')" width="100%" height="80vh">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <v-img :src="require('@/assets/wallet4good/wallet4good.svg')" max-width="300px"></v-img>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Our Self-Custody wallet is compatible with multiple networks, the vast majority of wallets and over 250 types of payments.
                          Can store digital, physical and hybrid assets. Can store custodial assets and self-custodial assets including FIAT, NFTs, Crypto, Hard Assets (digitized).
                          Mint from your wallet, and send and receive assets in a complete security as all is end to end encrypted.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row>
                    <v-col>
                      <VideoBannerComponent :src="require('@/assets/videos/id4good.mp4')" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align="left">
                        <div style="font-size: 1.4rem; line-height: 150%">
                          Fully protected by our self-identity app (iD4Good) as well as supporting cold wallet with Ledger hardware, Wallet4Good is a top safety Digital Vault that gives allowing swapping, trading, sell, buy, auction, payment plans, buy now pay later and much more. All that power in a free web based dApp that runs smoothly in any device capable of running modern web browsers.
                        </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-container>
                        <v-row>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/versatile2.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Versatile
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Multiple networks and tokens supported, as well as our Own network and tokens along with Traditional banking
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/secure.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Secure
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                iD4Good for login and unlock your Wallet and assets, Post-Quantum ready
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/ubiquitous.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Ubiquitous
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Web based dapp that can run in any modern web browser capable device
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/ledger.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                Ledger
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Ready for cold wallet in ledger environment enhanced hardware security
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div style="width: 200px">
                              <div>
                                <v-img :src="require('@/assets/icons/iconboxes/p2p.svg')" aspect-ratio="1" width="48px"></v-img>
                              </div>
                              <div style="color: #6EACE8; font-size: 1.2rem" class="my-1">
                                P2P
                              </div>
                              <div style="font-weight: 400; font-size: 1rem; line-height: 100%">
                                Fast and secure peer to peer transactions with any kind of asset
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href=https://wallet4good.com x-large>Open your wallet now</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center">
            <BannerTwoComponent fluid style="max-width: 2048px">
              <template v-slot:FirstBox>
                <v-img rel="preload" :src="require('@/assets/wallet4good/01.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
              <template v-slot:SecondBox>
                <v-img rel="preload" :src="require('@/assets/wallet4good/02.png')" width="100%" height="100%" max-height="60vh">
                </v-img>
              </template>
            </BannerTwoComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #1D4770">
              <template v-slot:content>
                <v-container style="min-height: 300px" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                        <div><span class="font-weight-bold">DeFi Power</span></div>
                      <div>At the tip of your fingers</div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">01</template>
              <template slot="Title"><v-icon class="mr-2">mdi-access-point-network</v-icon>Self-Custodial Wallet</template>
              <template slot="Content">Self-Custodial wallet which can toggle between Fiat and DEFI</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">02</template>
              <template slot="Title"><v-icon class="mr-2">mdi-thermometer-low</v-icon>Compatibility</template>
              <template slot="Content">Compatible with multiple networks, and majority of wallets</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">03</template>
              <template slot="Title"><v-icon class="mr-2">mdi-clock-fast</v-icon>Multiple Control</template>
              <template slot="Content">Vault wallet for institutions (ability to have multiple control of wallets)</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">04</template>
              <template slot="Title"><v-icon class="mr-2">mdi-thermometer-low</v-icon>Integration</template>
              <template slot="Content">3rd party integration using APIs to digitize existing banking systems</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SimpleInfoBoxComponent>
              <template slot="Index">05</template>
              <template slot="Title"><v-icon class="mr-2">mdi-charity</v-icon>Ability</template>
              <template slot="Content">Ability to store custodial assets and self-custodial assets including: FIAT, NFTs, Crypto, hard Assets (digitized)</template>
              <template slot="Button"><span></span></template>
            </SimpleInfoBoxComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent :custom-style="{ border: '0' }" fluid>
              <template v-slot:content>
                <v-img rel="preload" :src="require('@/assets/wallet4good/banner2.png')" width="100%" height="100%" position="top center">
                </v-img>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="min-height: 60vh" class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Peer2Peer <strong>Era</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10" align="left">
                          Peer2Peer transactions have never been more reliable, because all our technology is ready for the post quantum era, so your wallet and identity are fully protected. You can send and receive money or digital assets in complete privacy, quickly and securely for both edges from the moment your wallet is created.
                          Another advantage is instant currency exchange, entering the Fiat global economy faster than ever. And there is much more, because Wallet4Good supports several other networks and tokens, the use of hardware Ledger, wearables, Box4Good, and dApps and new business creation thanks to SandBox future release.
                        </div>
                        <div style="max-width: 350px">
                          <v-btn rounded outlined color="#6EACE8" class="px-10" style="border-width: medium" height="64px" href=https://wallet4good.com x-large>Open your wallet now</v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleDarkComponent fluid style="background-color: #70B2E6">
              <template v-slot:content>
                <v-container class="my-15">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div>
                        <div style="font-size: 150%">Get Going Download ID4Good, <strong>start now</strong></div>
                        <div style="font-size: 1.4rem; font-weight: normal" class="my-10">
                          Unleash all the power of our products and services with the industry leading self-custody digital identity. We have original products for you and there is much more to come from us and from our partners.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleDarkComponent>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <BannerSingleComponent fluid>
              <template v-slot:content>
                <v-container style="max-width: 1200px" class="my-10">
                  <v-row class="my-10">
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Android.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/Ledger.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-img :src="require('@/assets/ledger-store-badge.svg')" contain width="160px" max-height="45px"></v-img>
                    </v-col>
                    <v-col align-self="end" align="center" cols="12" sm="4" md="4">
                      <v-img :src="require('@/assets/id4good/ios.png')" aspect-ratio="1" height="400px" contain></v-img>
                      <v-btn icon href="https://apps.apple.com/ar/app/id4good/id1536997799">
                        <v-img :src="require('@/assets/google-play-badge.png')" contain width="160px" max-height="45px"></v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </BannerSingleComponent>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Wallet4GoodView.scss'
import meta from '@/mixins/meta'
import BannerSingleComponent from '@/components/BannerSingleComponent/BannerSingleComponent'
import NotifyComponent from '@/components/NotifyComponent/NotifyComponent'
import BannerSingleDarkComponent from '@/components/BannerSingleDarkComponent/BannerSingleDarkComponent'
import VideoBannerComponent from '@/components/VideoBannerComponent/VideoBannerComponent'
import BannerTwoComponent from '@/components/BannerTwoComponent/BannerTwoComponent'
import SimpleInfoBoxComponent from '@/components/SimpleInfoBoxComponent/SimpleInfoBoxComponent'

export default {
  name: 'Wallet4GoodView',
  components: { BannerTwoComponent, SimpleInfoBoxComponent, VideoBannerComponent, BannerSingleDarkComponent, NotifyComponent, BannerSingleComponent },
  mixins: [meta]
}
</script>
