<template>
    <div class="TestingView">
        <v-container style="height: 1000px">
          <v-row>
            <v-col>
              <RotorComponent :position="position" style="position: absolute; top: 200px; left: 10px; max-width: 90vw;" />
            </v-col>
            <v-col>
              <v-btn @click="move">+</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import RotorComponent from '@/components/RotorComponent/RotorComponent'
export default {
  name: 'TestingView',
  data () {
    return {
      position: 0
    }
  },
  components: { RotorComponent },
  methods: {
    move () {
      this.position++
      console.log(this.position)
      this.$forceUpdate()
    }
  }
}
</script>
